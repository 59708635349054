import React, { FC } from 'react'
import { SectionJournalLandingPageProps } from '@magal/models'
import { styled } from '@magal/styles'
import {
  Container,
  MediaCard,
  SectionWrap,
  BlockContent,
} from '@magal/components'

const priorityCardsNumber = 3

const RootContainer = styled('div', {
  color: '$green_09',
  display: 'grid',
  gap: '$72',
})

const Root = styled(Container, {})

const TextContainer = styled('div', {
  maxWidth: '$maxWidthS',
})

const Title = styled('h1', {
  projectFont: 'heading01',
  textTransform: 'uppercase',
  marginBottom: '$24',
})

const JournalCardsWrapper = styled('div', {
  display: 'grid',
  gap: '$12 $6',
  color: '$green_09',
  '@lg': {
    gap: '$56 $6',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
})

export const SectionJournalLandingPage: FC<SectionJournalLandingPageProps> = ({
  title,
  description,
  journalPosts,
  priority,
}) => {
  return (
    <SectionWrap type={'MARGINS_S'}>
      <RootContainer>
        <Root>
          <TextContainer>
            {title && <Title>{title}</Title>}
            {description && <BlockContent body={description} />}
          </TextContainer>
        </Root>
        <JournalCardsWrapper>
          {journalPosts?.map((journalPost, index) => (
            <MediaCard
              {...journalPost}
              cardSize={'default'}
              key={index}
              priority={index < priorityCardsNumber && priority}
              imageSizes={
                '(min-width: 1000px) 26vw, (min-width: 740px) 34vw, (min-width: 430px) 52vw, 80vw'
              }
            />
          ))}
        </JournalCardsWrapper>
      </RootContainer>
    </SectionWrap>
  )
}
