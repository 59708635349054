import { FC } from 'react'
import dynamic from 'next/dynamic'

import { PageBuilderSection, ShopifyData } from '@magal/models'

import { SectionProductConversionArea } from './SectionProductConversionArea/SectionProductConversionArea'
import { SectionHero } from './SectionHero/SectionHero'
import { SectionCollection } from './SectionCollection/SectionCollection'
import { SectionJournalLandingPage } from './SectionJournalLandingPage/SectionJournalLandingPage'
import { SectionJournalHero } from './SectionJournalHero/SectionJournalHero'
import { SectionUtilityPageHeader } from './SectionUtilityPageHeader/SectionUtilityPageHeader'
import { SectionSearch } from './SectionSearch/SectionSearch'
import { SectionUtilityPage } from './SectionUtilityPage/SectionUtilityPage'

// @ts-ignore
const DynamicSectionSample = dynamic(() =>
  import('./SectionSample/SectionSample').then((mod) => mod.SectionSample),
)

// @ts-ignore
const DynamicSectionCollectionsSlider = dynamic(() =>
  import('./SectionCollectionsSlider/SectionCollectionsSlider').then(
    (mod) => mod.SectionCollectionsSlider,
  ),
)

// @ts-ignore
const DynamicSectionCardsSlider = dynamic(() =>
  import('./SectionCardsSlider/SectionCardsSlider').then(
    (mod) => mod.SectionCardsSlider,
  ),
)

// @ts-ignore
const DynamicSectionThreeFeatures = dynamic(() =>
  import('./SectionThreeFeatures/SectionThreeFeatures').then(
    (mod) => mod.SectionThreeFeatures,
  ),
)

// @ts-ignore
const DynamicSectionTrustpilotBanner = dynamic(() =>
  import('./SectionTrustpilotBanner/SectionTrustpilotBanner').then(
    (mod) => mod.SectionTrustpilotBanner,
  ),
)

// @ts-ignore
const DynamicSectionQuotes = dynamic(() =>
  import('./SectionQuotes/SectionQuotes').then((mod) => mod.SectionQuotes),
)

// @ts-ignore
const DynamicSectionNewsletter = dynamic(() =>
  import('./SectionNewsletter/SectionNewsletter').then(
    (mod) => mod.SectionNewsletter,
  ),
)

// @ts-ignore
const DynamicSectionValueAndStyle = dynamic(() =>
  import('./SectionValueAndStyle/SectionValueAndStyle').then(
    (mod) => mod.SectionValueAndStyle,
  ),
)

// @ts-ignore
const DynamicSectionImageAndContent = dynamic(() =>
  import('./SectionImageAndContent/SectionImageAndContent').then(
    (mod) => mod.SectionImageAndContent,
  ),
)

// @ts-ignore
const DynamicSectionGreen = dynamic(() =>
  import('./SectionGreen/SectionGreen').then((mod) => mod.SectionGreen),
)

// @ts-ignore
const DynamicSectionProductRecommendations = dynamic(() =>
  import('./SectionProductRecommendations/SectionProductRecommendations').then(
    (mod) => mod.SectionProductRecommendations,
  ),
)

// @ts-ignore
const DynamicSectionFourFeatures = dynamic(() =>
  import('./SectionFourFeatures/SectionFourFeatures').then(
    (mod) => mod.SectionFourFeatures,
  ),
)

// @ts-ignore
const DynamicSectionRecentlyViewed = dynamic(() =>
  import('./SectionRecentlyViewed/SectionRecentlyViewed').then(
    (mod) => mod.SectionRecentlyViewed,
  ),
)

// @ts-ignore
const DynamicSectionJournalQuote = dynamic(() =>
  import('./SectionJournalQuote/SectionJournalQuote').then(
    (mod) => mod.SectionJournalQuote,
  ),
)

// @ts-ignore
const DynamicSectionJournalImage = dynamic(() =>
  import('./SectionJournalImage/SectionJournalImage').then(
    (mod) => mod.SectionJournalImage,
  ),
)

// @ts-ignore
const DynamicSectionJournalColumn = dynamic(() =>
  import('./SectionJournalColumn/SectionJournalColumn').then(
    (mod) => mod.SectionJournalColumn,
  ),
)

// @ts-ignore
const DynamicSectionJournalPostsAuthor = dynamic(() =>
  import('./SectionJournalPostsAuthor/SectionJournalPostsAuthor').then(
    (mod) => mod.SectionJournalPostsAuthor,
  ),
)

// @ts-ignore
const DynamicSectionLandscapeImage = dynamic(() =>
  import('./SectionLandscapeImage/SectionLandscapeImage').then(
    (mod) => mod.SectionLandscapeImage,
  ),
)

// @ts-ignore
const DynamicSectionJournalRelatedProducts = dynamic(() =>
  import('./SectionJournalRelatedProducts/SectionJournalRelatedProducts').then(
    (mod) => mod.SectionJournalRelatedProducts,
  ),
)

// @ts-ignore
const DynamicSectionFourSixtyInstagramFeed = dynamic(() =>
  import('./SectionFourSixtyInstagramFeed/SectionFourSixtyInstagramFeed').then(
    (mod) => mod.SectionFourSixtyInstagramFeed,
  ),
)

// @ts-ignore
const DynamicSectionOkendoReviews = dynamic(() =>
  import('./SectionOkendoReviews/SectionOkendoReviews').then(
    (mod) => mod.SectionOkendoReviews,
  ),
)

export type PageBuilderProps = {
  section: PageBuilderSection
  shopifyData: ShopifyData
}

export const PageBuilder: FC<PageBuilderProps> = ({ section, shopifyData }) => {
  /**
   * We use two section import strategies:
   * - Dynamic - in order to minimise the size of bundle chunks, we prefer to import sections dynamically
   * - Static - to prevent the "blink" effect in most exposed sections we use static imports
   */
  switch (section._type) {
    // dynamic sections ======================================
    case 'sectionOkendoReviews':
      return (
        <DynamicSectionOkendoReviews {...section} shopifyData={shopifyData} />
      )

    case 'sectionFourSixtyInstagramFeed':
      return <DynamicSectionFourSixtyInstagramFeed {...section} />
    case 'sectionJournalRelatedProducts':
      return (
        <DynamicSectionJournalRelatedProducts
          {...section}
          shopifyData={shopifyData}
        />
      )

    case 'sectionLandscapeImage':
      return <DynamicSectionLandscapeImage {...section} />

    case 'sectionRecentlyViewed':
      return <DynamicSectionRecentlyViewed {...section} />

    case 'sectionJournalPostsAuthor':
      return <DynamicSectionJournalPostsAuthor {...section} />

    case 'sectionJournalColumn':
      return <DynamicSectionJournalColumn {...section} />

    case 'sectionJournalImage':
      return <DynamicSectionJournalImage {...section} />

    case 'sectionJournalQuote':
      return <DynamicSectionJournalQuote {...section} />

    case 'sectionProductRecommendations':
      return (
        <DynamicSectionProductRecommendations
          {...section}
          shopifyData={shopifyData}
        />
      )
    case 'sectionFourFeatures':
      return <DynamicSectionFourFeatures {...section} />

    case 'sectionGreen':
      return <DynamicSectionGreen {...section} />

    case 'sectionImageAndContent':
      return <DynamicSectionImageAndContent {...section} />

    case 'sectionValueAndStyle':
      return <DynamicSectionValueAndStyle {...section} />

    case 'sectionNewsletter':
      return <DynamicSectionNewsletter {...section} />

    case 'sectionQuotes':
      return <DynamicSectionQuotes {...section} />

    case 'sectionTrustpilotBanner':
      return <DynamicSectionTrustpilotBanner {...section} />

    case 'sectionThreeFeatures':
      return <DynamicSectionThreeFeatures {...section} />

    case 'sectionCardsSlider':
      return <DynamicSectionCardsSlider {...section} />

    case 'sectionCollectionsSlider':
      return (
        <DynamicSectionCollectionsSlider
          {...section}
          shopifyData={shopifyData}
        />
      )

    case 'sectionSample':
      return <DynamicSectionSample {...section} shopifyData={shopifyData} />
    // static sections ======================================

    case 'sectionUtilityPageHeader':
      return <SectionUtilityPageHeader {...section} />

    case 'sectionJournalLandingPage':
      return <SectionJournalLandingPage {...section} />

    case 'sectionJournalHero':
      return <SectionJournalHero {...section} />

    case 'sectionCollection':
      return <SectionCollection {...section} shopifyData={shopifyData} />

    case 'sectionSearch':
      return <SectionSearch {...section} />

    case 'sectionHero':
      return <SectionHero {...section} />

    case 'sectionProductConversionArea':
      return (
        <SectionProductConversionArea {...section} shopifyData={shopifyData} />
      )

    case 'sectionUtilityPage':
      return <SectionUtilityPage {...section} />

    default:
      return null
  }
}
