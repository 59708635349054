import { styled } from '@magal/styles'
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'
import { ContainerUpsells } from './ContainerUpsells'
import { Close } from '@magal/icons'
import { useProductsSettings } from '@magal/product'
import { SiteConfiguration, Upsell } from '@magal/models'
import { getShopifyProductExtended } from '@magal/services/shopify-service'
import { captureException } from '@sentry/nextjs'
import { useCartContext, useComputedCart } from '@magal/cart'
import { useRouter } from 'next/router'

const Root = styled('section', {
  backgroundColor: '$white',
  borderRadius: '$r2',
  minWidth: '100%',
  padding: '30px 15px',
  '@lg': {
    backgroundColor: '$gray300',
    marginTop: '0',
    padding: '30px 33px 26px',
  },
})

const Headline = styled('h3', {
  alignItems: 'center',
  color: '$darkGreen',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '24px',
  projectFont: 'heading03',
  textAlign: 'center',
})

const CloseBtn = styled('button', {
  display: 'flex',
  background: 'transparent',
  color: '#000000',
})

const ContinueBtn = styled('button', {
  background: '$darkGreen',
  borderRadius: '$r1',
  color: '$white',
  fontSize: '16px !important',
  height: '$48',
  width: '100%',
  marginTop: '10px',
})

const useUpsellsData = (
  upsells: SiteConfiguration['product']['upsells'],
  locale: string | undefined,
) => {
  const { upsellItems } = upsells
  const [upsellsProducts, setUpsellsProducts] = useState<Upsell[]>([])

  useEffect(() => {
    if (!upsellItems) return
    const getUpsells = async () => {
      const tempUpsells = []

      for (let u = 0; u < upsellItems?.length; u++) {
        const item = upsellItems[u]

        if (!item || !item?.handle) return false

        const theItem = await getShopifyProductExtended(
          item?.handle,
          locale ?? 'en',
        )

        if (theItem.status === 'ERROR') {
          const exception = new Error(
            `[[...paths]] Sanity getSiteConfiguration function error ${JSON.stringify(
              theItem.errors,
            )}`,
          )
          captureException(exception)
          throw exception
        }

        const data: Upsell = {
          item: theItem.data,
          original: item.original || null,
        }
        tempUpsells.push(data)
      }
      return setUpsellsProducts(tempUpsells)
    }
    getUpsells()
  }, [upsellItems])

  return {
    upsellsProducts,
  }
}

interface Props {
  handleShowUpsells: () => void
}

export const Upsells: FC<Props> = ({ handleShowUpsells }) => {
  const [isAddedUpsell, setIsAddedUpsell] = useState(false)
  const { upsells } = useProductsSettings()
  const { openMiniCart } = useCartContext()
  const { t } = useTranslation('cart')
  const { computedCartLines } = useComputedCart()
  const { locale } = useRouter()
  const { upsellsProducts } = useUpsellsData(upsells || {}, locale)

  const LABEL_TEXT = {
    NO_THANKS: t('noThanks'),
    CONTINUE_CART: t('continueToCart'),
  }

  useEffect(() => {
    if (computedCartLines?.length === 0) setIsAddedUpsell(false)

    const findups = upsellsProducts.find((upsellProd) => {
      return computedCartLines?.find((line) => {
        return line.merchandise.id === upsellProd?.item?.variants[0]?.id
      })
    })

    if (!findups) setIsAddedUpsell(true)
    else setIsAddedUpsell(false)
  }, [JSON.stringify(computedCartLines)])

  if (!upsells?.upsellItems || !upsellsProducts) return null

  const handleClose = () => {
    openMiniCart(t('addedToCart'))
    handleShowUpsells()
  }

  return (
    <Root>
      <Headline>
        {upsells?.title}
        <CloseBtn onClick={handleClose}>
          <Close />
        </CloseBtn>
      </Headline>
      <ContainerUpsells upsellsItems={upsellsProducts} />
      <ContinueBtn onClick={handleClose}>
        {isAddedUpsell ? LABEL_TEXT.NO_THANKS : LABEL_TEXT.CONTINUE_CART}
      </ContinueBtn>
    </Root>
  )
}
