import { FC } from 'react'
import { styled } from '@magal/styles'
import { SectionHeroProps } from '@magal/models'
import {
  Container,
  Link,
  Media,
  SectionWrap,
  BlockContent,
} from '@magal/components'

const Root = styled(Container, {
  position: 'relative',
  color: '$green_09',
  display: 'grid',
  rowGap: '$24',
  variants: {
    twoColumns: {
      true: {
        '@lg': {
          gridTemplateColumns: 'repeat(3, 1fr)',
          alignItems: 'end',
          columnGap: '$32',
        },
      },
      false: {
        '@lg': {
          color: '$white',
        },
      },
    },
    noMargins: {
      true: {
        margin: '0 !important',
      },
    },
  },
})

const Content = styled('div', {
  height: '100%',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  maxWidth: '$maxWidthS',
  '@lg': {
    order: 1,
  },
  variants: {
    isOnImage: {
      true: {
        '@lg': {
          gridRow: 1,
          gridColumn: 1,
          zIndex: 9,
          padding: '$32 0 $32 $32',
        },
      },
    },
  },
})

const ContentInner = styled('div', {
  gridRow: '2',
  display: 'grid',
  gridGap: '$24',
  position: 'sticky',
  bottom: '$containerMarginMobile',
  '@lg': {
    bottom: '$containerMarginDesktop',
  },
  pointerEvents: 'none',
})

const Heading = styled('h1', {
  projectFont: 'caps01',
})

const SubHeading = styled('h2', {
  projectFont: 'caps03',
  marginBottom: '$24',
})

const Buttons = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  lin: {
    gap: ['$8', '$20'],
  },
  zIndex: '10',
})

const ImageContainer = styled('div', {
  position: 'relative',
  display: 'grid',
  gridColumn: '1',
  '@lg': {
    gridColumn: 'span 2',
    order: 2,
  },
  variants: {
    isFullscreen: {
      true: {
        gridRow: 1,
        gridColumn: 1,
      },
    },
    withTwoImages: {
      true: {
        '@lg': {
          gridColumn: 'span 2',
          paddingBottom: `${(5 / 7) * 100}%`,
        },
      },
    },
  },
})

const StyledMedia1 = styled('div', {
  display: 'block',
  width: '100%',
  borderRadius: '$r2',
  overflow: 'hidden',
  variants: {
    width: {
      narrow: {
        width: '70%',
        '@lg': {
          position: 'absolute',
          placeSelf: 'end start',
        },
      },
      full: {
        width: '100%',
      },
    },
    noRadius: {
      true: {
        borderRadius: 0,
      },
    },
  },
})

const StyledMedia2 = styled('div', {
  zIndex: '1',
  position: 'absolute',
  placeSelf: 'end end',
  width: '47%',
  borderRadius: '$r2',
  overflow: 'hidden',
  '@lg': {
    position: 'absolute',
    placeSelf: 'start end',
  },
})

const StyledButton = styled(Link, {
  pointerEvents: 'all',
})

const CardLink = styled(Link, {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
})

export const SectionHero: FC<SectionHeroProps> = ({
  color,
  title,
  subtitle,
  description,
  buttons,
  images,
  url,
  type,
  priority,
}) => {
  const isOnlyImage = type === 'only-image'

  return (
    <SectionWrap type={isOnlyImage ? 'MARGINS_0' : 'MARGINS_S'}>
      <Root
        twoColumns={(type !== 'fullscreen-image' && !isOnlyImage) ?? false}
        noMargins={isOnlyImage ?? false}
      >
        <ImageContainer
          key={title}
          isFullscreen={type === 'fullscreen-image' ?? false}
          withTwoImages={type === 'two-images' ?? false}
        >
          {images && images[0] && (
            <StyledMedia1
              width={type === 'two-images' ? 'narrow' : 'full'}
              noRadius={isOnlyImage ?? false}
            >
              {isOnlyImage && url ? (
                <a href={url.href}>
                  <Media {...images[0]} priority={priority} sizes={'100vw'} />
                </a>
              ) : (
                <Media
                  {...images[0]}
                  priority={priority}
                  sizes={type === 'two-images' ? '70vw' : '100vw'}
                />
              )}
            </StyledMedia1>
          )}
          {images && images[1] && type === 'two-images' && (
            <StyledMedia2>
              <Media {...images[1]} priority={priority} sizes={'47vw'} />
            </StyledMedia2>
          )}
        </ImageContainer>
        {type !== 'only-image' && (
          <Content
            isOnImage={type === 'fullscreen-image' ?? false}
            css={{ '@lg': { color: color?.hex } }}
          >
            {buttons && buttons.length > 0 && (
              <CardLink href={buttons[0].link?.href} tabIndex={-1} />
            )}
            <ContentInner>
              {subtitle && <SubHeading>{subtitle}</SubHeading>}
              {title && <Heading>{title}</Heading>}
              {description && <BlockContent body={description} />}
              {buttons && buttons.length > 0 && (
                <Buttons>
                  {buttons.map(({ link, label }, index) => (
                    <StyledButton
                      {...link}
                      key={`${label}-${index}`}
                      appearance="outlineCurrentColor"
                    >
                      {label}
                    </StyledButton>
                  ))}
                </Buttons>
              )}
            </ContentInner>
          </Content>
        )}
      </Root>
    </SectionWrap>
  )
}
