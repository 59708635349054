import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { ShopifyCollectionSortKey, ShopifyProduct } from '@magal/models'
import { styled } from '@magal/styles'
import {
  Button,
  Container,
  Loader,
  ProductCard,
  ProductGrid,
  Rule,
  SectionWrap,
} from '@magal/components'
import { useInView } from 'react-intersection-observer'
import { useRouter } from 'next/router'
import { getLocaleRegionIdFromPath } from '@magal/utils'
import { ChevronDown } from '@magal/icons'
import { useTranslation } from 'react-i18next'
import {
  DEFAULT_PROJECT_SORT_KEY,
  PROJECT_SORT_KEY_MAP,
  ROUTE_SEARCH,
} from '@magal/configs'
import { PromoTile } from '@magal/components'
import { getShopifySearchResults } from '@magal/services/shopify-service'
import { SectionSearchProps } from '@magal/models'
import { SearchInput } from '@magal/search'
import { useProductsSettings } from '@magal/product'
import debounce from 'lodash/debounce'

const Observer = styled('div', {
  position: 'relative',
})

const RootWrap = styled('div', {
  display: 'grid',
  gap: '$32',
})

const SearchStatusWrap = styled('div', {
  display: 'grid',
  placeContent: 'center',
  projectFont: 'caps04',
  minHeight: '3em',
  color: '$green_09',
})

const SearchWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  textAlign: 'center',
  margin: '0 auto',
  '@md': {
    maxWidth: '$maxWidthM',
  },
})

const CollectionUtils = styled('div', {
  display: 'inline-grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  placeContent: 'center start',
  gap: '$24',
  borderTop: '1px solid $green_09',
  borderBottom: '1px solid $green_09',
  '@lg': {
    borderTop: 'none',
    borderBottom: '1px solid $green_09',
  },
})
const SelectWrap = styled('div', {
  projectFont: 'body01',
  display: 'grid',
  gap: '$4',
  gridTemplateColumns: 'auto auto',
  svg: {
    gridArea: '1 / 2',
    placeSelf: 'center end',
    pointerEvents: 'none',
  },
  color: '$green_09',
  padding: '$16',
  '@lg': {
    border: 'none',
  },
})
const SortSelectLabel = styled('label', {
  gridArea: '1 / 1',
  alignSelf: 'center',
})
const SortSelect = styled('select', {
  gridArea: '1 / 2',
  appearance: 'none',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  projectFont: 'body01',
  color: '$green_09',
  padding: '0 $24 0 $4',
  cursor: 'pointer',
  background: 'transparent',
})

const ProductCardWrap = styled('div', {
  display: 'grid',
  variants: {
    featured: {
      true: {
        gridColumn: 'span 2',
      },
    },
  },
})
const Grid = styled(ProductGrid, {
  position: 'relative',
  gridAutoFlow: 'row dense',
  transition: 'opacity 300ms ease-in-out',
  variants: {
    loading: {
      true: {
        // opacity: 0.6,
      },
    },
  },
})
const Status = styled('div', {
  display: 'grid',
  placeContent: 'center',
  placeItems: 'center',
  textAlign: 'center',
  height: '$240',
  projectFont: 'caps07',
  color: '$green_09',
  gridGap: '$8',
  button: {
    projectFont: 'caps07',
  },
})

const UnlockWrap = styled('div', {
  justifySelf: 'center',
  textAlign: 'center',
  color: '$green_09',
  margin: '$96 $32',
  display: 'grid',
  placeItems: 'center',
  gap: '$32',
  span: {
    projectFont: 'caps03',
  },
})

export const SectionSearch: FC<SectionSearchProps> = ({ promoTiles }) => {
  const { updateProductColorsMap } = useProductsSettings()
  const router = useRouter()
  const { locale, query } = router
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const { t } = useTranslation('collection')

  const searchInputRef = useRef<HTMLInputElement>(null)

  const queryValueFromRouter = Array.isArray(query.q)
    ? query.q[0]
    : query.q || ''

  const sortKeyFromRouter = (() => {
    const val = Array.isArray(query.sort)
      ? query.sort[0]
      : query.sort || undefined

    return val && Object.keys(PROJECT_SORT_KEY_MAP).includes(val)
      ? val
      : undefined
  })()

  const computedSortKey = sortKeyFromRouter
    ? PROJECT_SORT_KEY_MAP[sortKeyFromRouter].sortKey
    : PROJECT_SORT_KEY_MAP[DEFAULT_PROJECT_SORT_KEY].sortKey
  // const computedReverse = sortKeyFromRouter
  //   ? PROJECT_SORT_KEY_MAP[sortKeyFromRouter].reverse
  //   : PROJECT_SORT_KEY_MAP[DEFAULT_PROJECT_SORT_KEY].reverse

  const [searchInputValue, setSearchInputValue] =
    useState<string>(queryValueFromRouter)

  const [state, setState] = useState<{
    query?: string
    isLoading: boolean
    isLocked: boolean
    isDirty: boolean
    products: ShopifyProduct[]
    sortKey?: ShopifyCollectionSortKey
    reverse?: boolean
    pageInfo?: {
      endCursor: string
      hasNextPage: boolean
    }
  }>({
    query: undefined,
    isLoading: true,
    isLocked: true,
    isDirty: false,
    sortKey: undefined,
    reverse: undefined,
    pageInfo: undefined,
    products: [],
  })
  const [observerRef, isObserverInView] = useInView()

  const loadSearchResults: (loadNextPage?: boolean) => void = async (
    loadNextPage = false,
  ) => {
    if (!queryValueFromRouter || queryValueFromRouter === '') {
      setState({ ...state, query: undefined, products: [], isLoading: false })
      return
    }

    // snapshot just to avoid server/app states differences
    const querySnapshot = {
      query: queryValueFromRouter,
      sortKey: computedSortKey,
      reverse: false,
    }

    setState({
      ...state,
      isLoading: true, // show loader
    })

    const res = await getShopifySearchResults(
      {
        query: querySnapshot.query,
        reverse: querySnapshot.reverse,
        sortKey: querySnapshot.sortKey,
        afterCursor: loadNextPage ? state.pageInfo?.endCursor : undefined,
      },
      regionId,
      localeId,
    )
    if (res) {
      setState({
        ...state,
        sortKey: querySnapshot.sortKey,
        reverse: querySnapshot.reverse,
        query: querySnapshot.query,
        products: loadNextPage
          ? [...state.products, ...res.products]
          : res.products,
        pageInfo: res.pageInfo,
        isLoading: false,
        isLocked: !loadNextPage,
        isDirty: !!sortKeyFromRouter,
      })
    }
  }

  const refineQuery = ({
    value,
    sortKey,
  }: {
    value?: string
    sortKey?: string
  }) => {
    const q = value ?? queryValueFromRouter
    const sort = sortKey ?? sortKeyFromRouter ?? undefined

    router.push(
      {
        pathname: ROUTE_SEARCH,
        query: sort ? { q, sort } : { q },
      },
      undefined,
      {
        shallow: true,
      },
    )
  }

  const debouncedRefineQuery = useCallback(debounce(refineQuery, 250), [query])

  const handleInputChange = (value: string) => {
    setSearchInputValue(value)
  }

  const handleInputResetRequest = () => {
    setSearchInputValue('')
    if (searchInputRef.current) searchInputRef.current?.focus()
  }

  useEffect(() => {
    if (isObserverInView && !state.isLoading && state.pageInfo?.hasNextPage) {
      loadSearchResults(true)
    }
  }, [isObserverInView])

  useEffect(() => {
    if (!router.isReady) return
    setSearchInputValue(queryValueFromRouter)
    loadSearchResults()
  }, [query, router.isReady])

  useEffect(() => {
    if (searchInputValue !== state.query) {
      debouncedRefineQuery({ value: searchInputValue })
    }
    return () => {
      debouncedRefineQuery.cancel()
    }
  }, [searchInputValue])

  useEffect(() => {
    updateProductColorsMap(state.products.map((p) => p.fullTitle))
  }, [state.products])

  const putResultsMessage = () =>
    state.products.length === 0
      ? t('noResults', { ns: 'search' })
      : `${
          state.pageInfo?.hasNextPage
            ? t('showingResultsFor.showingFirst', {
                ns: 'search',
              })
            : t('showingResultsFor.showing', {
                ns: 'search',
              })
        } ${state.products.length} ${t('showingResultsFor.resultsFor', {
          ns: 'search',
        })} "${state.query}"`
  return (
    <SectionWrap type={'MARGINS_S'}>
      <RootWrap>
        <Container>
          <SearchWrap>
            <SearchInput
              value={searchInputValue}
              onResetRequest={handleInputResetRequest}
              onChange={handleInputChange}
              onSubmit={() => refineQuery({ value: searchInputValue })}
              ref={searchInputRef}
            />
            <SearchStatusWrap>
              {!router.isReady || state.isLoading ? (
                <Loader size={'small'} />
              ) : queryValueFromRouter === '' ? (
                t('startSearching', { ns: 'search' })
              ) : (
                putResultsMessage()
              )}
            </SearchStatusWrap>
          </SearchWrap>
        </Container>
        {state.products.length > 0 && (
          <>
            <CollectionUtils>
              <SelectWrap>
                <SortSelectLabel htmlFor="sort-select">
                  {t('sortBy')}:
                </SortSelectLabel>
                <SortSelect
                  name={'sort'}
                  id={'sort-select'}
                  value={sortKeyFromRouter ?? DEFAULT_PROJECT_SORT_KEY}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    refineQuery({ sortKey: e.target.value })
                  }
                >
                  {Object.keys(PROJECT_SORT_KEY_MAP).map((key) => (
                    <option value={key} key={key}>
                      {t(key)}
                    </option>
                  ))}
                </SortSelect>
                <ChevronDown />
              </SelectWrap>
            </CollectionUtils>
            <Grid loading={state.isLoading}>
              {state.products?.map((product, i) => {
                return (
                  <ProductCardWrap
                    style={{ order: i + 1 }}
                    key={product.id}
                    featured={product.featured}
                  >
                    <ProductCard
                      image={product.featuredImage}
                      title={product.title}
                      handle={product.handle}
                      priceRange={product.priceRange}
                      color={product.color}
                      compareAtPriceRange={product.compareAtPriceRange}
                      featured={product.featured}
                    />
                  </ProductCardWrap>
                )
              })}
              {!state.isDirty &&
                state.products &&
                promoTiles?.map((tile, index) => {
                  if (
                    typeof tile.index === 'number' &&
                    tile.index > state.products.length
                  )
                    return null
                  return (
                    <PromoTile
                      key={`searchPromoTile-${tile.index}-${index}`}
                      {...tile}
                    />
                  )
                })}
            </Grid>
            {state.pageInfo?.hasNextPage && state.isLocked ? (
              <>
                <UnlockWrap>
                  <span>
                    {t('youHaveSeen', { number: state.products?.length })}
                  </span>
                  <Button
                    appearance={'outlineGreen'}
                    onClick={() => loadSearchResults(true)}
                  >
                    {t('unlockAllItems')}
                  </Button>
                </UnlockWrap>
              </>
            ) : (
              <>
                <Observer ref={observerRef} />
                <Status>
                  {state.isLoading ? (
                    <Loader size={'small'} />
                  ) : state.pageInfo?.hasNextPage ? (
                    <Button onClick={() => loadSearchResults(true)}>
                      load more
                    </Button>
                  ) : (
                    'all products loaded'
                  )}
                </Status>
              </>
            )}
          </>
        )}
        <Rule />
      </RootWrap>
    </SectionWrap>
  )
}
