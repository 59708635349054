import { ShopifyImageMediaPayload, ShopifyPrice } from '@magal/models'
import { styled } from '@magal/styles'
import { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom/'

interface Props {
  media: ShopifyImageMediaPayload[]
  children: React.ReactNode
  isHidden: boolean
  currentPrice: ShopifyPrice
}

const Root = styled('section', {
  borderTop: '1px solid black',
  backgroundColor: 'white',
  display: 'flex',
  padding: '22px',
  gap: '22px',
  position: 'absolute',
  minHeight: '100px',
  width: '100%',
  zIndex: 100,
  '@lg': {
    minHeight: '136px',
    border: '1px solid black',
    maxWidth: '1083px',
    width: '90%',
  },
})

const Picture = styled('picture', {
  display: 'none',
  backgroundColor: '$gray300',
  '@lg': {
    display: 'flex',
  },
})

const Img = styled('img', {
  height: '73px',
})

export const PortalProductCart = ({ media, children, isHidden }: Props) => {
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portalProduct')
    setMounted(true)
  }, [])

  useEffect(() => {
    if (isHidden && ref.current !== null) {
      ref.current?.classList.add('showPortal')
    } else {
      ref.current?.classList.remove('showPortal')
    }
  }, [isHidden])

  const PortalElements = () => (
    <Root>
      <Picture>
        <Img src={media[0].image.src} alt={media[0].image.alt ?? ''} />
      </Picture>
      {children}
    </Root>
  )

  return mounted === true && ref.current !== null
    ? createPortal(<PortalElements />, ref.current)
    : null
}
