import {
  AccordionHeaderProps,
  AccordionStateful,
  Button,
} from '@magal/components'
import { Minus, Plus } from '@magal/icons'
import { styled } from '@magal/styles'
import React, { FC } from 'react'

const AccordionHeadingStyled = styled(Button, {
  display: 'flex',
  projectFont: 'body01',
  color: '$green_09',
  height: '$buttonHeightL',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: '1px solid $green_09',
  paddingLeft: '$16',
  paddingRight: '$16',
  '@lg': {
    paddingLeft: '$24',
    paddingRight: '$24',
  },
})

const DescriptionWrap = styled('div', {
  paddingBottom: '$28',
  paddingLeft: '$16',
  paddingRight: '$16',
  '@lg': {
    paddingLeft: '$24',
    paddingRight: '$24',
  },
})

const ProductAccordionHeading: FC<
  AccordionHeaderProps & { title?: string }
> = ({ title, isOpen, ...restProps }) => {
  return (
    <AccordionHeadingStyled {...restProps}>
      <span>{title}</span>
      {isOpen ? <Minus /> : <Plus />}
    </AccordionHeadingStyled>
  )
}

export const ProductAccordion: React.FC<{
  title?: string
  initiallyOpen?: boolean
}> = ({ children, title, initiallyOpen = false }) => {
  return (
    <AccordionStateful
      accordionId={`product-accordion-${title}`}
      isInitiallyOpen={initiallyOpen}
      header={(headerElementProps) => (
        <ProductAccordionHeading {...headerElementProps} title={title} />
      )}
    >
      <DescriptionWrap>{children}</DescriptionWrap>
    </AccordionStateful>
  )
}
