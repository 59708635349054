import { Media } from '@magal/components'
import { FC } from 'react'
import { styled } from '@magal/styles'
import { SectionProductConversionAreaProps } from '@magal/models'
import { handlePopUpTrigger } from '@magal/services/gtm-service'
import { useRouter } from 'next/router'

const IconWrap = styled('div', {
  width: '38px',
  marginRight: '$8',
  '@md': {
    width: '50px',
    marginRight: '$12',
  },
})

const TileWrap = styled('div', {
  display: 'inline-flex',
  justifySelf: 'baseline',
  border: '2px solid transparent',
  maxWidth: '$240',
  padding: '$6 $8',
  marginTop: '$8',
  alignItems: 'center',
  alignSelf: 'flex-start',
  cursor: 'pointer',
  '@md': {
    padding: '$4 $12 $4 $8',
    marginTop: '$12',
  },
})

const Text = styled('p', {
  projectFont: 'heading04Medium',
  lineHeight: 1.2,
  fontWeight: 'bold',
  fontSize: '10px',
  '@md': {
    fontSize: '12px',
  },
})

export const ProductTiles: FC<{
  tiles: SectionProductConversionAreaProps['productTiles']
  attentiveIds?: number[]
}> = ({ tiles, attentiveIds }) => {
  const { locale } = useRouter()

  if (!tiles) return null

  let attentiveMobile = 477968
  let attentiveDesktop = 477961

  let filteredTiles = tiles.filter((tile) => tile.enabled === true)

  if (attentiveIds && filteredTiles.length > 1) {
    attentiveMobile = attentiveIds[0]
    attentiveDesktop = attentiveIds[1]

    filteredTiles = [filteredTiles[filteredTiles.length - 1]]
  } else {
    filteredTiles = [filteredTiles[0]]
  }

  return (
    <>
      {filteredTiles.map((t, index) => (
        <TileWrap
          css={{
            backgroundColor: t.bgColor.hex,
            borderColor: t.borderColor?.hex || t.bgColor.hex,
            borderRadius: `${t.borderRadius || 0}px`,
          }}
          key={index}
          onClick={() =>
            handlePopUpTrigger(locale, [attentiveMobile, attentiveDesktop])
          }
        >
          <IconWrap>
            <Media {...t.icon} sizes={'50px'} />
          </IconWrap>
          <Text
            css={{
              color: t.textColor.hex,
              whiteSpace: t.text.includes('<br') ? 'nowrap' : 'normal',
            }}
            dangerouslySetInnerHTML={{ __html: t.text }}
          ></Text>
        </TileWrap>
      ))}
    </>
  )
}
