import { Button, EditorialModal } from '@magal/components'
import { ShippingBird } from '@magal/icons'
import { PortableText } from '@magal/models'
import { styled } from '@magal/styles'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Root = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$20',
  '@md': {
    justifyContent: 'space-between',
  },
})

const ShippingShortInfo = styled('div', {
  display: 'none',
  gridAutoFlow: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '$20',
  '@md': {
    display: 'grid',
  },
})

const ShippingInfoCaption = styled('span', {
  projectFont: 'body04',
})

const ShippingInfoLink = styled(Button, {
  projectFont: 'body04',
  textDecoration: 'underline',
  color: '$green_09',
  textAlign: 'right',
})

export type ShippingInfoProps = {
  shortInfo?: string
  modalContent?: PortableText
}

export const ShippingInfo: FC<ShippingInfoProps> = ({
  shortInfo,
  modalContent,
}) => {
  const { t } = useTranslation('shippingInfo')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const toggleModalState = () => setIsOpenModal((prevIsOpen) => !prevIsOpen)

  return (
    <Root>
      {shortInfo && (
        <ShippingShortInfo>
          <ShippingBird />
          <ShippingInfoCaption>{shortInfo}</ShippingInfoCaption>
        </ShippingShortInfo>
      )}
      {modalContent && (
        <>
          <ShippingInfoLink onClick={toggleModalState}>
            {t('shippingAndDeliveryInformation')}
          </ShippingInfoLink>

          <EditorialModal
            modalTitle={t('shippingAndDeliveryInformation')}
            modalContent={modalContent}
            isOpen={isOpenModal}
            onRequestClose={toggleModalState}
          />
        </>
      )}
    </Root>
  )
}
