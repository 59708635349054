import { PricePrimitive } from '@magal/components'
import { ShopifyPriceRange } from '@magal/models'
import { styled } from '@magal/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductAccordion } from '../ProductAccordion/ProductAccordion'

const ProgressBarLabel = styled('p', {
  projectFont: 'caps07',
  marginTop: '$28',
})

const ProgressBar = styled('div', {
  display: 'grid',
  backgroundColor: '$gray400',
  height: '$16',
  marginTop: '$12',
  borderRadius: '$r2',
})

const ProgressGreen = styled('div', {
  width: '50%',
  display: 'grid',
  backgroundColor: '$green',
  height: '$16',
  borderRadius: '$r2',
})

const ProgressDarkGreen = styled('div', {
  width: '50%',
  display: 'grid',
  backgroundColor: '$green_09',
  height: '$16',
  borderRadius: '$r2 0 0 $r2',
})

const Legend = styled('div', {
  marginTop: '$28',
  marginBottom: '$28',
})

const LegendItem = styled('div', {
  display: 'grid',
  gridTemplateColumns: '$16 1fr',
  marginTop: '$8',
  columnGap: '$20',
})

const Color = styled('div', {
  width: '$16',
  height: '$16',
  borderRadius: '50%',
  backgroundColor: '$gray400',
  variants: {
    color: {
      green: {
        backgroundColor: '$green',
      },
      darkGreen: {
        backgroundColor: '$green_09',
      },
    },
  },
})

const ColorDescription = styled('p', {
  projectFont: 'body04',
})

export type AccordionTransparentPricingProps = {
  title?: string
  priceRange: ShopifyPriceRange
  transparentPricingMultiplier: number
}

export const AccordionTransparentPricing: FC<
  AccordionTransparentPricingProps
> = ({ title, priceRange, transparentPricingMultiplier }) => {
  const { t } = useTranslation('sectionProductConversionArea')

  return (
    <ProductAccordion title={title}>
      <ProgressBarLabel>
        {t('accordionTransparentPricing.ourPricing')} —{' '}
        <PricePrimitive price={priceRange.minVariantPrice} />
      </ProgressBarLabel>
      <ProgressBar css={{ width: '33%' }}>
        <ProgressGreen css={{ width: '100%' }}>
          <ProgressDarkGreen />
        </ProgressGreen>
      </ProgressBar>
      <ProgressBarLabel>
        {t('accordionTransparentPricing.traditionalRetailPricing')} —{' '}
        <PricePrimitive
          price={{
            amount: (
              parseFloat(priceRange.minVariantPrice.amount) *
              transparentPricingMultiplier
            ).toString(),
            currencyCode: priceRange.minVariantPrice.currencyCode,
          }}
        />
      </ProgressBarLabel>
      <ProgressBar>
        <ProgressGreen>
          <ProgressDarkGreen />
        </ProgressGreen>
      </ProgressBar>
      <Legend>
        <LegendItem>
          <Color color={'darkGreen'} />
          <ColorDescription>
            {t('accordionTransparentPricing.transparentPricingLabel1')}
          </ColorDescription>
        </LegendItem>
        <LegendItem>
          <Color color={'green'} />
          <ColorDescription>
            {t('accordionTransparentPricing.transparentPricingLabel2')}
          </ColorDescription>
        </LegendItem>
        <LegendItem>
          <Color />
          <ColorDescription>
            {t('accordionTransparentPricing.transparentPricingLabel3')}
          </ColorDescription>
        </LegendItem>
      </Legend>
    </ProductAccordion>
  )
}
