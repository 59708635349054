import React, { FC, ReactComponentElement, useState } from 'react'
import { ProductSwatches, ArrowInCircleButton } from '@magal/components'
import {
  AdvancedProductOption,
  Option,
  ShopifyProductColor,
} from '@magal/models'
import { styled, keyframes } from '@magal/styles'
import { ProductOption } from './ProductOption'
import { ChevronRight } from '@magal/icons'
import { useProductsSettings } from '@magal/product'
import { useTranslation } from 'react-i18next'
import { ProductUploadFile } from './ProductUploadFile'
import { ProductMap } from './ProductMap/ProductMap'

const Root = styled('div', {
  display: 'grid',
  pCardProductrojectFont: 'body04',
  gap: '$16',
  color: '$green_09',
  padding: '$12 0',
  '@lg': {
    gap: '$12',
    padding: '$28',
    borderRadius: '$r2',
    background: '$gray300',
  },
})
const ColorsWrap = styled('div', {
  display: 'grid',
  gridGap: '$16',
  placeContent: 'center',
})
const Title = styled('div', {
  projectFont: 'body05',
  textAlign: 'center',
  userSelect: 'none',
})

const Head = styled('div', {
  display: 'grid',
  gridGap: '$16',
  alignItems: 'center',
  width: '100%',
  gridTemplateColumns: 'auto 1fr auto',
})
const SelectedVal = styled('div', {
  projectFont: 'caps07',
  height: '1em',
  textAlign: 'center',
})
const StepCounter = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  textAlign: 'center',
  projectFont: 'caps08',
})
const ArrowAnimationKeyframe = keyframes({
  '0%': { transform: 'translateX(0); opacity: 1' },
  '100%': { transform: 'translateX(-0.4em); opacity: .5' },
})

const DoneIcon = styled('div', {
  width: '$48',
  height: '$48',
})

export const ProductWizard: FC<{
  template: string | undefined
  productColors?: ShopifyProductColor[]
  options?: AdvancedProductOption[]
  onChange: (optionId: string, valueId?: string, attrs?: any) => void
  onDone: (checkAllDone?: any) => void
  currentProductColorId?: string
  addToCart: ReactComponentElement<any>
  productAttributes: {
    key: string
    value: string
  }[]
}> = ({
  template,
  productColors,
  options,
  addToCart,
  currentProductColorId,
  productAttributes,
  onChange,
  onDone,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const { colorsConfig } = useProductsSettings()

  const { t } = useTranslation('sectionProductConversionArea')

  if (!options || options.length === 0) return null

  type ComputedOption = AdvancedProductOption | Option<'product-colors'>

  const computedOptions: ComputedOption[] = (() => {
    if (productColors) {
      return [
        {
          _type: 'product-colors',
          _key: 'product-colors',
          title: t('chooseMaterial'),
          selectedValue: currentProductColorId,
        },
        ...options,
      ]
    } else {
      return options
    }
  })()

  const numberOfIris = (options: AdvancedProductOption[], multiplier = '') => {
    if (!multiplier || multiplier === '') return 1

    const findMultiplier = options.find(
      (option: AdvancedProductOption) => option.title === multiplier,
    )

    if (findMultiplier) {
      const findNumber = findMultiplier.selectedValue?.replace(/[^0-9]/g, '')

      return Number(findNumber)
    }

    return 1
  }

  const advancedTemplate = template === 'ADVANCED_TEMPLATE'

  const staticOptions: ComputedOption[] = computedOptions.filter(
    (o: ComputedOption) =>
      o.title &&
      (o.title.match(/(?:[0-9])(?:st|nd|rd|th)/gm) ||
        o.title.match(/\d+/) === null),
  )

  const isSizePicker = (title: string): boolean =>
    ['size', 'length', 'longueur'].some((option: string) =>
      title.toLowerCase().includes(option),
    )

  const allButLastOptions: ComputedOption[] = staticOptions.filter(
    (o: ComputedOption, i: number) =>
      !(i === staticOptions.length - 1 && isSizePicker(o.title)),
  )

  const lastOption: ComputedOption[] = staticOptions.filter(
    (o: ComputedOption, i: number) =>
      i === staticOptions.length - 1 && isSizePicker(o.title),
  )

  const fixedOptions: ComputedOption[][] = allButLastOptions.map(
    (o: ComputedOption) => Array.of(o),
  )

  const groupItems = (
    arr: AdvancedProductOption[],
  ): AdvancedProductOption[][] => {
    const temp = []
    for (let i = 1; i <= 10; i++) {
      temp.push(
        arr.filter((option: AdvancedProductOption) => {
          return (
            parseInt(`${option.title.match(/\d+/)}`) === i &&
            option.title.match(/(?:[0-9])(?:st|nd|rd|th)/gm) === null
          )
        }),
      )
    }

    return temp.filter((o) => o.length > 0)
  }

  const myOptions = groupItems(options)
  const myOptionsUseful = myOptions.length > 0 && myOptions[0].length > 1
  const myOptionsArr = computedOptions.map((i) => [i])

  let productOptions: ComputedOption[][] = myOptionsUseful
    ? [...fixedOptions, ...myOptions]
    : myOptionsArr

  const checkMapAndMarker = () => {
    const mapOpt = productOptions.find((opt) => opt[0]._type === 'map')

    if (!mapOpt) return [false, false]

    const hasMarker = mapOpt[0].mapMarker && mapOpt[0].mapMarker !== ''

    return [!!mapOpt, hasMarker, mapOpt[0].mapMarker]
  }

  const hasMapAndMarker = checkMapAndMarker()
  let markerOption: ComputedOption | null = null
  let hasDiamond: ComputedOption | null = null

  if (hasMapAndMarker[0] && hasMapAndMarker[1]) {
    if (hasMapAndMarker[2]) {
      const theMarkerOption = productOptions.find(
        (opt) => opt[0].title === hasMapAndMarker[2],
      )

      if (theMarkerOption) {
        markerOption = theMarkerOption[0]
      } else {
        const lookForDiamond = productOptions.find((opt) =>
          opt[0].title.toLowerCase().includes('diamond'),
        )

        if (lookForDiamond) {
          hasDiamond = lookForDiamond[0]
        }
      }
    }

    productOptions = productOptions.filter(
      (po) => po[0].title !== hasMapAndMarker[2],
    )
  }

  if (lastOption.length > 0 && myOptionsUseful) productOptions.push(lastOption)

  let stepsCount = computedOptions?.length
  let currentOption = computedOptions[currentStepIndex]
  let currentGroup: ComputedOption[] = []

  let isPrevStepDisabled = currentStepIndex === 0
  let isPrevVisuallyHidden = currentStepIndex === 0
  let isNextStepDisabled =
    currentStepIndex === stepsCount - 1 || !currentOption?.selectedValue
  let isNextVisuallyHidden = currentStepIndex === stepsCount - 1

  let checkAllDone = computedOptions.every(
    (option: ComputedOption) => option.selectedValue !== '',
  )

  if (advancedTemplate) {
    stepsCount = productOptions?.length
    currentGroup = productOptions[currentStepIndex]
    currentOption = productOptions[currentStepIndex][0]

    isPrevStepDisabled = currentStepIndex === 0
    isPrevVisuallyHidden = currentStepIndex === 0

    isNextStepDisabled =
      currentStepIndex === stepsCount - 1 ||
      !productOptions[currentStepIndex].every(
        (o) => o.selectedValue && o.selectedValue !== '',
      )
    isNextVisuallyHidden = currentStepIndex === stepsCount - 1

    checkAllDone = productOptions.every((optionGroup: ComputedOption[]) =>
      optionGroup.every(
        (option: ComputedOption) =>
          (option.selectedValue && option.selectedValue !== '') ||
          option.values === null,
      ),
    )
  }

  const multipleOptionsTitle = (arr: ComputedOption[], index = 0): string => {
    const ordinals = ['', 'st', 'nd', 'rd', 'th']
    let prefix = 0
    const group = arr.map((step: ComputedOption) => {
      const theNumber =
        step.title.match(/(?:[0-9])(?:st|nd|rd|th)/gm) === null &&
        step.title.match(/\d+/)
      prefix = theNumber ? parseInt(`${theNumber[0]}`) : 0

      return theNumber ? step.title.replace(/[0-9]/g, '') : step.title
    })

    const ordinalsIndex =
      prefix >= ordinals.length ? ordinals.length - 1 : prefix

    return `${prefix > 0 ? `${t('choose')} ${prefix}` : ''}${
      ordinals[ordinalsIndex]
    } ${group[index]}:`
  }

  if (currentGroup.length > 1 && currentGroup[1]._type === 'multi-text-input') {
    currentGroup.reverse()
  }

  const stepTitle = currentGroup
    ? multipleOptionsTitle(currentGroup)
    : currentOption?.title

  const secondTitle =
    currentGroup.length > 1 ? multipleOptionsTitle(currentGroup, 1) : ''

  onDone(checkAllDone)

  const ArrowAnimation = styled(
    'div',
    isNextStepDisabled
      ? {}
      : {
          transition: 'transform .3s ease-in-out',
          transform: 'translateX(0); opacity: 1',

          '&:not(:hover)': {
            animation: `${ArrowAnimationKeyframe} .8s infinite alternate ease-in-out`,
          },
        },
  )

  return (
    <Root>
      <StepCounter>
        <span>
          Step {currentStepIndex + 1} of {stepsCount}
        </span>
      </StepCounter>
      <Head>
        <ArrowInCircleButton
          onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
          disabled={isPrevStepDisabled}
          visuallyHidden={isPrevVisuallyHidden}
        />
        <Title>
          {stepTitle === ' _uploadedFile:' ? 'Upload File' : stepTitle}
        </Title>
        {isNextVisuallyHidden ? (
          <DoneIcon>{addToCart}</DoneIcon>
        ) : (
          <ArrowAnimation>
            <ArrowInCircleButton
              onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
              disabled={isNextStepDisabled}
              visuallyHidden={isNextVisuallyHidden}
              type={'next'}
            >
              <ChevronRight />
            </ArrowInCircleButton>
          </ArrowAnimation>
        )}
      </Head>

      {!advancedTemplate &&
        computedOptions?.map((option, i) => {
          if (i !== currentStepIndex) return null
          if (option._type === 'product-colors') {
            return (
              <ColorsWrap key={'product-colors'}>
                <ProductSwatches
                  currentProductColorId={currentProductColorId}
                  productColors={productColors}
                  preventScroll
                />
                <SelectedVal>
                  {currentProductColorId &&
                    colorsConfig &&
                    colorsConfig[currentProductColorId]?.label}
                </SelectedVal>
              </ColorsWrap>
            )
          } else {
            return (
              <ProductOption
                secondTitle={secondTitle}
                option={option}
                key={option.title}
                onChange={onChange}
                onGoToNextStep={() => setCurrentStepIndex(currentStepIndex + 1)}
              />
            )
          }
        })}

      {advancedTemplate &&
        productOptions?.map((option: ComputedOption[], i) => {
          if (i !== currentStepIndex) return null
          return option.map((opt: ComputedOption) => {
            if (opt._type === 'product-colors') {
              return (
                <ColorsWrap key={'product-colors'}>
                  <ProductSwatches
                    currentProductColorId={currentProductColorId}
                    productColors={productColors}
                    preventScroll
                  />
                  <SelectedVal>
                    {currentProductColorId &&
                      colorsConfig &&
                      colorsConfig[currentProductColorId]?.label}
                  </SelectedVal>
                </ColorsWrap>
              )
            } else if (opt._type === 'upload-file') {
              return (
                <ProductUploadFile
                  onChange={onChange}
                  option={opt}
                  numberOfFiles={
                    numberOfIris(options, opt.multiplierOptionTitle) || 1
                  }
                />
              )
            } else if (opt._type === 'map') {
              return (
                <ProductMap
                  productAttributes={productAttributes}
                  productColor={currentProductColorId}
                  option={opt}
                  onChange={onChange}
                  markerOption={markerOption}
                  hasDiamond={hasDiamond}
                />
              )
            } else {
              return (
                <ProductOption
                  secondTitle={secondTitle}
                  option={opt}
                  key={opt.title}
                  onChange={onChange}
                  onGoToNextStep={() =>
                    setCurrentStepIndex(currentStepIndex + 1)
                  }
                />
              )
            }
          })
        })}
    </Root>
  )
}
