import { FC } from 'react'
import { styled } from '@magal/styles'
import { BlockContent, Container, SectionWrap } from '@magal/components'
import { SectionUtilityPageHeaderProps } from '@magal/models'

const Root = styled(Container, {
  color: '$green_09',
})

const Text = styled('div', {
  display: 'grid',
  gap: '$16',
})

const Title = styled('h1', {
  projectFont: 'heading01',
  textTransform: 'uppercase',
})

const DescriptionText = styled('div', {
  projectFont: 'body01',
  maxWidth: '$maxWidthS',
})

export const SectionUtilityPageHeader: FC<SectionUtilityPageHeaderProps> = ({
  text,
}) => {
  return (
    <SectionWrap type={'MARGINS_S'}>
      <Root>
        <Text>
          {text?.title && <Title>{text?.title}</Title>}
          {text?.description && (
            <DescriptionText>
              <BlockContent body={text?.description} />
            </DescriptionText>
          )}
        </Text>
      </Root>
    </SectionWrap>
  )
}
