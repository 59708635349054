import { FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { styled } from '@magal/styles'
import { Link } from '@magal/components'

const TitleContainer = styled('div', {})

const Title = styled(Link, {
  display: 'grid',
  gap: '$12',
  alignItems: 'center',
  justifyContent: 'left',
  gridAutoFlow: 'column',
  position: 'relative',
  projectFont: 'body01',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  variants: {
    isActive: {
      true: {
        '&:before': {
          display: 'block-inline',
          content: '',
          borderRadius: '100%',
          width: '$4',
          height: '$4',
          backgroundColor: '$green_09',
          opacity: 1,
        },
      },
    },
  },
})

interface PanelItemProps {
  panel?: {
    title?: string
    href?: string
  }
  slider: HTMLDivElement | null
  onClick: () => void
  href?: string
  isActive?: boolean
}

export const MenuItem: FC<PanelItemProps> = ({
  panel,
  slider,
  onClick,
  href,
  isActive = false,
}) => {
  const [ref] = useInView({
    root: slider,
    threshold: 1,
  })

  return (
    <TitleContainer ref={ref} onClick={onClick}>
      <Title href={href} isActive={isActive}>
        {panel?.title}
      </Title>
    </TitleContainer>
  )
}
