import { FC, useEffect } from 'react'
import { styled } from '@magal/styles'
import { Media } from '@magal/components'
import { useInView } from 'react-intersection-observer'
import { ShopifyMediaPayload } from '@magal/models'

const MediaWrap = styled('div', {
  scrollSnapAlign: 'start',
  width: '100vw',
  backgroundColor: '$gray300',

  '@lg': {
    width: 'auto',
  },
})

interface ProductGalleryItemProps {
  image: ShopifyMediaPayload
  imageSizes?: string
  slider: HTMLDivElement | null
  onIntersecting: () => void
  priority?: boolean
}

export const ProductGalleryItem: FC<ProductGalleryItemProps> = ({
  image,
  imageSizes,
  slider,
  onIntersecting,
  priority = false,
}) => {
  const [refMediaWrap, isMediaWrapInView] = useInView({
    root: slider,
    threshold: 0.6,
  })

  useEffect(() => {
    onIntersecting()
  }, [isMediaWrapInView])

  return (
    <MediaWrap ref={refMediaWrap}>
      <Media
        {...image}
        mediaPayload={image}
        hardcropRatio={'portrait2'}
        sizes={imageSizes}
        priority={priority}
      />
    </MediaWrap>
  )
}
