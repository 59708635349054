import { FC } from 'react'
import { styled } from '@magal/styles'
import { UpsellProduct } from './UpsellProduct'
import { Upsell } from '@magal/models'

const Root = styled('article', {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  minWidth: '100%',
})

interface Props {
  upsellsItems: Upsell[]
}

export const ContainerUpsells: FC<Props> = ({ upsellsItems }) => {
  if (!upsellsItems) return null

  return (
    <Root>
      {upsellsItems.map((product, index) => (
        <UpsellProduct key={index} product={product} />
      ))}
    </Root>
  )
}
