import { FC, useEffect, useState } from 'react'
import { styled } from '@magal/styles'
import { AddLinesInput, Upsell } from '@magal/models'
import { Price } from './Price'
import { useProductsSettings } from '@magal/product'
import { UPSELL_ATTRIBUTE_KEY } from '@magal/configs'
import { useCartContext, useComputedCart } from '@magal/cart'
import { Loader } from '@magal/components'
import { useTranslation } from 'react-i18next'

const Root = styled('div', {
  backgroundColor: '$gray400',
  borderRadius: '$r2',
  display: 'flex',
  gap: '$16',
  justifyContent: 'space-between',
  padding: '$18 $14',
  minWidth: '100%',
})

const Picture = styled('picture', {
  width: '174px',
  height: '177px',
  '& img': {
    backgroundColor: '$gray300',
    height: '100%',
    objectFit: 'contain',
    width: '100%',
  },
})

const Small = styled('small', {
  color: '$darkGreen',
  marginBottom: '$6',
  projectFont: 'body06',
})

const Title = styled('h4', {
  color: '$darkGreen',
  projectFont: 'body01',
  marginBottom: '$24',
  width: '100%',
})

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '54%',
})

const Button = styled('button', {
  background: 'transparent',
  border: '1px solid $black',
  borderRadius: '$r2',
  height: '50px',
  marginTop: 'auto',
  projectFont: 'caps06',
})

const Center = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
})

const ButtonText = styled('span', {
  color: '#000',
})

interface Props {
  product: Upsell
}

export const UpsellProduct: FC<Props> = ({ product }) => {
  const { colorsConfig } = useProductsSettings()
  const { original, item } = product
  const [isToAdd, setIsToAdd] = useState(true)
  const {
    color,
    compareAtPriceRange,
    featuredImage,
    priceRange,
    title,
    variants,
  } = JSON.parse(JSON.stringify(item))
  const { addCartLines } = useCartContext()
  const { computedCartLines, removeCartLines } = useComputedCart()
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation('cart')

  useEffect(() => {
    const findLine = computedCartLines?.find(
      (line) => line.merchandise.id === variants[0].id,
    )
    if (computedCartLines?.length === 0) {
      setIsToAdd(true)
      return
    }

    if (findLine?.id) {
      setIsToAdd(false)
    } else setIsToAdd(true)
  }, [computedCartLines])

  const colorName = colorsConfig && color ? colorsConfig[color] : ''

  const handleAddToCart = async () => {
    if (isToAdd) {
      setIsLoading(true)
      const linesToAdd: AddLinesInput = [
        {
          merchandiseId: variants[0].id,
          quantity: 1,
          attributes: [
            {
              key: UPSELL_ATTRIBUTE_KEY,
              value: 'true',
            },
            {
              key: 'Special Deal',
              value: 'Addon',
            },
          ],
        },
      ]

      if (original) {
        linesToAdd[0].attributes?.push({
          key: '_original_url',
          value: original,
        })
      }

      const addedLines = await addCartLines(linesToAdd)

      if (addedLines) {
        setIsToAdd(false)
        setIsLoading(false)
      }
    } else {
      const findLine = computedCartLines?.find(
        (line) => line.merchandise.id === variants[0].id,
      )
      if (findLine?.id) {
        setIsLoading(true)
        removeCartLines([findLine?.id])
        window.setTimeout(() => {
          setIsToAdd(true)
          setIsLoading(false)
        }, 500)
      }
    }
  }

  return (
    <Root>
      <Picture>
        <img
          src={featuredImage?.image?.src}
          alt={featuredImage?.image?.alt || ''}
        />
      </Picture>
      <Content>
        {colorName && <Small>{colorName.label}</Small>}
        <Title>{title}</Title>
        <Price
          priceRange={priceRange}
          compareAtPriceRange={compareAtPriceRange}
        />
        <Button onClick={handleAddToCart}>
          <ButtonText>
            {isLoading ? (
              <Center>
                <Loader size={'small'} />
              </Center>
            ) : isToAdd ? (
              t('addToCart')
            ) : (
              t('remove')
            )}
          </ButtonText>
        </Button>
      </Content>
    </Root>
  )
}
