import React, { FC, useEffect, useRef, useState } from 'react'
import {
  SectionCollectionProps,
  ShopifyCollectionSortKey,
  ShopifyProduct,
} from '@magal/models'
import { styled } from '@magal/styles'
import {
  Button,
  Container,
  Loader,
  ProductCard,
  ProductGrid,
  Rule,
} from '@magal/components'
import { useInView } from 'react-intersection-observer'
import { getShopifyCollectionProducts } from '@magal/services/shopify-service'
import { useRouter } from 'next/router'
import { getLocaleRegionIdFromPath } from '@magal/utils'
import { ChevronDown } from '@magal/icons'
import { useTranslation } from 'react-i18next'
import { DEFAULT_PROJECT_SORT_KEY, PROJECT_SORT_KEY_MAP } from '@magal/configs'
import { PromoTile } from '@magal/components'
import { useProductsSettings } from '@magal/product'
import { GTMViewItemList } from '@magal/services/gtm-service'
import { captureException } from '@sentry/nextjs'

const Observer = styled('div', {
  position: 'relative',
})

const TopWrap = styled('div', {
  position: 'relative',
  paddingTop: '$16',
  color: '$green_09',
  borderBottom: '1px solid $green_09',
  '@lg': {
    paddingTop: '$64',
  },
})
const Title = styled('h1', {
  projectFont: 'caps01',
})
const Description = styled('p', {
  marginTop: '$16',
  projectFont: 'body01',
  maxWidth: '540px',
})
const CollectionUtils = styled('div', {
  paddingTop: '$2',
  paddingBottom: '$16',
  display: 'inline-grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  placeContent: 'center start',
  gridGap: '$24',
})
const SelectWrap = styled('div', {
  projectFont: 'body01',
  display: 'grid',
  gap: '$4',
  gridTemplateColumns: 'auto auto',
  height: '$36',
  svg: {
    gridArea: '1 / 2',
    placeSelf: 'center end',
    pointerEvents: 'none',
  },
})
const SortSelectLabel = styled('label', {
  gridArea: '1 / 1',
  alignSelf: 'center',
})
const SortSelect = styled('select', {
  gridArea: '1 / 2',
  appearance: 'none',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  projectFont: 'body01',
  color: '$green_09',
  padding: '0 $24 0 $4',
  cursor: 'pointer',
  background: 'transparent',
})

const ProductCardWrap = styled('div', {
  display: 'grid',
  variants: {
    featured: {
      true: {
        gridColumn: 'span 2',
      },
    },
  },
})
const Grid = styled(ProductGrid, {
  paddingTop: '$2',
  position: 'relative',
  gridAutoFlow: 'row dense',
  // transition: 'opacity 200ms ease 200ms', // TODO: profile it
  variants: {
    loading: {
      true: {
        // opacity: 0.6,
      },
    },
  },
})
const Status = styled('div', {
  display: 'grid',
  placeContent: 'center',
  placeItems: 'center',
  textAlign: 'center',
  height: '$240',
  projectFont: 'caps07',
  color: '$green_09',
  gridGap: '$8',
  button: {
    projectFont: 'caps07',
  },
})

const UnlockWrap = styled('div', {
  justifySelf: 'center',
  textAlign: 'center',
  color: '$green_09',
  margin: '$96 $32',
  display: 'grid',
  placeItems: 'center',
  gap: '$32',
  span: {
    projectFont: 'caps03',
  },
})

export const SectionCollection: FC<SectionCollectionProps> = ({
  collection,
  shopifyData,
  promoTiles,
}) => {
  const router = useRouter()
  const { locale, replace, query } = router
  const { updateProductColorsMap } = useProductsSettings()
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const { t } = useTranslation('collection')

  const isFirstTimeAccessed = useRef(true)

  const queryProjectSortKey = query.sort

  const baseCollectionProducts = shopifyData?.collection?.products?.items || []

  const [state, setState] = useState<{
    isLoading: boolean
    isLocked: boolean
    isDirty: boolean
    products: ShopifyProduct[]
    sortKey?: ShopifyCollectionSortKey
    reverse?: boolean
    pageInfo?: {
      endCursor: string
      hasNextPage: boolean
    }
  }>({
    isLoading: false,
    isLocked: true,
    isDirty: false,
    pageInfo: collection.products.pageInfo,
    products: [],
  })
  const [observerRef, isObserverInView] = useInView()

  const startCollectionOperation = () =>
    setState({
      ...state,
      pageInfo: undefined,
      isLoading: true, // show loader
      isLocked: false,
    })

  const loadMoreProducts = async () => {
    if (!state.pageInfo) {
      captureException(
        new Error('[loadMoreProducts] Can not find pageInfo for collection'),
      )
      return
    }
    startCollectionOperation()
    const res = await getShopifyCollectionProducts(
      {
        handle: collection.handle,
        afterCursor: state.pageInfo.endCursor,
        sortKey: state.sortKey,
        reverse: state.reverse,
      },
      regionId,
      localeId,
    )
    if (res.status === 'ERROR') {
      captureException(
        new Error('[loadMoreProducts] Can not fetch collection products'),
      )
      return
    }
    if (res.data) {
      setState({
        ...state,
        products: [...state.products, ...res.data.products],
        pageInfo: res.data.pageInfo,
        isLoading: false,
        isLocked: false,
      })
    }
  }
  const sortCollection = async (
    sortKey: ShopifyCollectionSortKey,
    reverse?: boolean,
  ) => {
    startCollectionOperation()
    const res = await getShopifyCollectionProducts(
      { handle: collection.handle, sortKey, reverse },
      regionId,
      localeId,
    )
    if (res.status === 'ERROR') {
      captureException(
        new Error('[sortCollection] Can not fetch collection products'),
      )
      return
    }
    if (res.data) {
      setState({
        ...state,
        products: res.data.products,
        pageInfo: res.data.pageInfo,
        sortKey: sortKey,
        reverse,
        isLoading: false,
        isLocked: true,
        isDirty: true,
      })
    }
  }

  const resetCollection = () => {
    setState({
      ...state,
      products: [],
      pageInfo: collection.products.pageInfo,
      sortKey: undefined,
      isLoading: false,
      isLocked: true,
      isDirty: false,
    })
  }

  const onSortKeyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    startCollectionOperation()
    replace(
      {
        query: {
          ...query,
          sort: e.target.value,
        },
      },
      undefined,
      {
        shallow: true,
      },
    )
  }
  // const onReset = () => {
  //   startCollectionOperation()
  //   replace(
  //     {
  //       query: {
  //         paths: query.paths,
  //       },
  //     },
  //     undefined,
  //     { shallow: true },
  //   )
  // }

  const computedCollectionProducts = state.isDirty
    ? state.products
    : [...baseCollectionProducts, ...state.products]

  useEffect(() => {
    if (isObserverInView && !state.isLoading && state.pageInfo?.hasNextPage) {
      loadMoreProducts()
    }
  }, [isObserverInView])

  useEffect(() => {
    updateProductColorsMap(computedCollectionProducts.map((p) => p.fullTitle))
  }, [state.products])

  useEffect(() => {
    if (
      queryProjectSortKey &&
      typeof queryProjectSortKey === 'string' &&
      Object.keys(PROJECT_SORT_KEY_MAP).includes(queryProjectSortKey)
    ) {
      sortCollection(
        PROJECT_SORT_KEY_MAP[queryProjectSortKey].sortKey,
        PROJECT_SORT_KEY_MAP[queryProjectSortKey].reverse,
      )
    } else {
      if (isFirstTimeAccessed.current) {
        isFirstTimeAccessed.current = false
      } else {
        resetCollection()
      }
    }
  }, [query])

  useEffect(() => {
    if (collection.title) {
      GTMViewItemList(
        computedCollectionProducts,
        'section_collection',
        collection.title,
      )
    }
  }, [JSON.stringify(computedCollectionProducts)])

  return (
    <div>
      <TopWrap>
        <Container>
          <Title>{collection.title}</Title>
          <Description>{collection.description}</Description>
          <CollectionUtils>
            <SelectWrap>
              <SortSelectLabel htmlFor="sort-select">
                {t('sortBy')}:
              </SortSelectLabel>
              <SortSelect
                name={'sort'}
                id={'sort-select'}
                value={queryProjectSortKey ?? DEFAULT_PROJECT_SORT_KEY}
                onChange={onSortKeyChange}
              >
                {Object.keys(PROJECT_SORT_KEY_MAP).map((key) => (
                  <option value={key} key={key}>
                    {t(key)}
                  </option>
                ))}
              </SortSelect>
              <ChevronDown />
            </SelectWrap>
            {/*<button onClick={onReset}>reset</button>*/}
          </CollectionUtils>
        </Container>
      </TopWrap>
      <Grid loading={state.isLoading}>
        {computedCollectionProducts.map((product, i) => {
          return (
            <ProductCardWrap
              style={{ order: i + 1 }}
              key={product.id}
              featured={product.featured}
            >
              <ProductCard
                image={product.featuredImage}
                title={product.title}
                handle={product.handle}
                priceRange={product.priceRange}
                compareAtPriceRange={product.compareAtPriceRange}
                featured={product.featured}
                color={product.color}
                tags={product.tags}
                priority={i <= 3}
              />
            </ProductCardWrap>
          )
        })}
        {!state.isDirty &&
          promoTiles?.map((tile) => {
            if (
              typeof tile.index === 'number' &&
              tile.index > computedCollectionProducts.length
            )
              return null
            return <PromoTile key={`tile-${tile.index}`} {...tile} />
          })}
      </Grid>
      {state.isLocked ? (
        <UnlockWrap>
          <span>
            {t('youHaveSeen', { number: computedCollectionProducts.length })}
          </span>
          <Button appearance={'outlineGreen'} onClick={loadMoreProducts}>
            {t('unlockAllItems')}
          </Button>
        </UnlockWrap>
      ) : (
        <>
          <Observer ref={observerRef} />
          <Status>
            {state.isLoading ? (
              <Loader size={'small'} />
            ) : state.pageInfo?.hasNextPage ? (
              <Button onClick={loadMoreProducts}>load more</Button>
            ) : (
              t('allProductsLoaded')
            )}
          </Status>
        </>
      )}
      <Rule />
    </div>
  )
}
