import { FC, useRef, useState, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import html2canvas from 'html2canvas'
import { client } from 'filestack-react'
import { styled } from '@magal/styles'
import { OptionMap } from '@magal/models'
import { Media } from '@magal/components'
import { ProductOption } from '../ProductOption'

mapboxgl.accessToken =
  'pk.eyJ1IjoianVhbi1tb3Jlbm8iLCJhIjoiY2x4ZHczZngyMDUyZTJqb2hsMDUyMzJzZiJ9.iI0pPdUCk5tR9c1IflAVdA'

const INITIAL_COOR = {
  LNG: -73.968285,
  LAT: 40.785091,
  ZOOM: 13.5,
  MAX_ZOOM: 13,
}

const MapWrapper = styled('div', {
  position: 'relative',
  zIndex: 1,
  overflow: 'hidden',
})
const Map = styled('div', {})
const MapPreview = styled('div', {
  position: 'absolute',
  width: '100%',
  minHeight: '100%',
  top: -7,
  bottom: 0,
  pointerEvents: 'none',
  zIndex: 11,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url('data:image/svg+xml,<svg width="479" height="333" viewBox="0 0 479 333" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M479 0H0V333H479V0ZM240 269C291.915 269 334 226.915 334 175C334 123.085 291.915 81 240 81C188.085 81 146 123.085 146 175C146 226.915 188.085 269 240 269Z" fill="white" fill-opacity="0.9"/><circle cx="240" cy="175" r="92.5" stroke="black" stroke-width="3"/></svg>')`,
  variants: {
    yellow: {
      true: {
        backgroundImage: `url('data:image/svg+xml,<svg width="479" height="336" viewBox="0 0 479 336" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M479 3H0V336H479V3ZM240 272C291.915 272 334 229.915 334 178C334 126.085 291.915 84 240 84C188.085 84 146 126.085 146 178C146 229.915 188.085 272 240 272Z" fill="black"/><circle cx="240" cy="178" r="91.5" stroke="%23EDD291" stroke-width="5"/><g filter="url(%23filter0_d_207_40)"><path d="M191.577 3L238 83.4064L240.232 82.1177L242.464 83.4064L288.887 3H284.268L240.232 79.2724L196.196 3H191.577Z" fill="%23EDD291"/><path d="M237 79C237 78.4477 237.448 78 238 78H242C242.552 78 243 78.4477 243 79V84C243 84.5523 242.552 85 242 85H238C237.448 85 237 84.5523 237 84V79Z" fill="%23EDD291"/></g><defs><filter id="filter0_d_207_40" x="189.577" y="0" width="101.31" height="86" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="-1"/><feGaussianBlur stdDeviation="1"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_207_40"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_207_40" result="shape"/></filter></defs></svg>')`,
      },
    },
    silver: {
      true: {
        backgroundImage: `url('data:image/svg+xml,<svg width="479" height="336" viewBox="0 0 479 336" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M479 3H0V336H479V3ZM240 272C291.915 272 334 229.915 334 178C334 126.085 291.915 84 240 84C188.085 84 146 126.085 146 178C146 229.915 188.085 272 240 272Z" fill="black"/><circle cx="240" cy="178" r="91.5" stroke="%23CFCCCC" stroke-width="5"/><g filter="url(%23filter0_d_207_86)"><path d="M191.577 3L238 83.4064L240.232 82.1177L242.464 83.4064L288.887 3H284.268L240.232 79.2724L196.196 3H191.577Z" fill="%23CFCCCC"/><path d="M237 79C237 78.4477 237.448 78 238 78H242C242.552 78 243 78.4477 243 79V84C243 84.5523 242.552 85 242 85H238C237.448 85 237 84.5523 237 84V79Z" fill="%23CFCCCC"/></g><defs><filter id="filter0_d_207_86" x="189.577" y="0" width="101.31" height="86" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="-1"/><feGaussianBlur stdDeviation="1"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_207_86"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_207_86" result="shape"/></filter></defs></svg>')`,
      },
    },
    pink: {
      true: {
        backgroundImage: `url('data:image/svg+xml,<svg width="479" height="336" viewBox="0 0 479 336" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M479 3H0V336H479V3ZM240 272C291.915 272 334 229.915 334 178C334 126.085 291.915 84 240 84C188.085 84 146 126.085 146 178C146 229.915 188.085 272 240 272Z" fill="black"/><circle cx="240" cy="178" r="91.5" stroke="%23F3C9AC" stroke-width="5"/><g filter="url(%23filter0_d_207_6)"><path d="M191.577 3L238 83.4064L240.232 82.1177L242.464 83.4064L288.887 3H284.268L240.232 79.2724L196.196 3H191.577Z" fill="%23F3C9AC"/><path d="M237 79C237 78.4477 237.448 78 238 78H242C242.552 78 243 78.4477 243 79V84C243 84.5523 242.552 85 242 85H238C237.448 85 237 84.5523 237 84V79Z" fill="%23F3C9AC"/></g><defs><filter id="filter0_d_207_6" x="189.577" y="0" width="101.31" height="86" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="-1"/><feGaussianBlur stdDeviation="1"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_207_6"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_207_6" result="shape"/></filter></defs></svg>')`,
      },
    },
  },
})

const ViewSwitcher = styled('div', {
  display: 'flex',
})

const ImageMarker = styled('div', {
  position: 'absolute',
  zIndex: 100,
  top: -10,
  border: `3px solid transparent`,
  borderRadius: 100,
  variants: {
    small: {
      true: {
        width: 18,
        height: 18,
        top: 'calc(50% - 9px)',
        left: 'calc(50% - 9px)',
      },
    },
    mid: {
      true: {
        width: 26,
        height: 26,
        top: 'calc(50% - 13px)',
        left: 'calc(50% - 13px)',
      },
    },
    large: {
      true: {
        width: 32,
        height: 32,
        top: 'calc(50% - 16px)',
        left: 'calc(50% - 16px)',
      },
    },
    yellow: {
      true: {
        borderColor: '#E9D399',
      },
    },
    silver: {
      true: {
        borderColor: '#CFCCCC',
      },
    },
    pink: {
      true: {
        borderColor: '#F3C9AC',
      },
    },
  },
})

const ViewItem = styled('button', {
  border: '1px solid $black',
  width: '100%',
  padding: '10px 15px',
  projectFont: 'body05',
  textAlign: 'center',
  userSelect: 'none',
  variants: {
    isActive: {
      true: {
        backgroundColor: '$green',
        color: '$white',
      },
    },
  },
})

const colorMapping = [
  {
    key: 'gold',
    value: 'yellow',
    hex: '#E9D399',
    mapId: 'clxw282nh04lz01qo8sx36uxb',
  },
  {
    key: 'silver',
    value: 'silver',
    hex: '#CFCCCC',
    mapId: 'clxw2dxal001v01qcckepf075',
  },
  {
    key: 'rose',
    value: 'pink',
    hex: '#F3C9AC',
    mapId: 'clxw2g11h001j01qhh94nff6n',
  },
]

export const ProductMap: FC<{
  option: OptionMap
  productColor: string | undefined
  productAttributes: {
    key: string
    value: string
  }[]
  markerOption: any
  hasDiamond: any
  onChange: (optionId: string, valueId?: string, attrs?: any) => void
}> = ({ productColor, onChange, option, markerOption, hasDiamond }) => {
  const resultMapRef = useRef<HTMLDivElement>(null)
  const defaultMapRef = useRef<HTMLDivElement>(null)
  const [initMap, setInitMap] = useState<any>(null)
  const [finalMap, setFinalMap] = useState<any>(null)
  const [marker, setMarker] = useState<any>(null)
  const [markerImg, setMarkerImg] = useState<any>(null)
  const [markerImgSize, setMarkerImgSize] = useState<string>('')
  const [lng, setLng] = useState(INITIAL_COOR.LNG)
  const [lat, setLat] = useState(INITIAL_COOR.LAT)
  const [lng2, setLng2] = useState(INITIAL_COOR.LNG)
  const [lat2, setLat2] = useState(INITIAL_COOR.LAT)
  const [zoom, setZoom] = useState(INITIAL_COOR.ZOOM)
  const [mapResult, setMapResult] = useState(false)
  const [placeName, setPlaceName] = useState<string>('')
  const [theResultColor, setTheResultColor] = useState<string>('yellow')
  const productColorToValue = colorMapping.find((color) =>
    productColor?.includes(color.key),
  )
  const showDiamondMarker = ['yes', 'oui', 'ja'].some((op) => {
    const title = hasDiamond.selectedValue.toLowerCase()

    return title.includes(op) && title.includes('+')
  })

  const fileStackKey = 'AitklXBUSjeGqbpvWwyLYz'
  const filesStack = client.init(fileStackKey)

  const generateMapImage = async () => {
    const element = document.getElementById('map-result')
    if (!element) return

    const canvas = await html2canvas(element)
    const data = canvas.toDataURL('image/jpg')
    const file = await (await fetch(data)).blob()
    const fu = await filesStack.upload(file, {}, {})

    return fu.url
  }

  const handleMapChange = (place_name: string) => {
    setTimeout(async () => {
      const getMapImg = await generateMapImage()

      onChange(option.title, place_name, [
        {
          key: '_mapPreviewImage',
          value: getMapImg,
        },
      ])
    }, 350)
  }

  useEffect(() => {
    setTheResultColor(
      productColorToValue ? productColorToValue?.value : 'yellow',
    )

    if (mapResult) {
      handleMapChange(placeName)
    }
  }, [mapResult])

  useEffect(() => {
    if (finalMap) {
      finalMap.setCenter([lng2, lat2])
      finalMap.setZoom(zoom)
    }
  }, [lng2, lat2, zoom])

  useEffect(() => {
    if (!(defaultMapRef && defaultMapRef.current)) return

    const defaultMap = new mapboxgl.Map({
      container: defaultMapRef.current,
      style: 'mapbox://styles/mapbox/standard',
      center: [lng, lat],
      zoom: zoom,
      maxZoom: INITIAL_COOR.MAX_ZOOM,
    })

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      flyTo: { duration: 0 },
    })

    geocoder.on('result', async ({ result }) => {
      setPlaceName(result.place_name)
      setMapResult(true)
    })

    defaultMap.addControl(new mapboxgl.GeolocateControl(), 'top-right')
    defaultMap.addControl(new mapboxgl.NavigationControl(), 'top-right')
    defaultMap.addControl(geocoder, 'top-left')

    const marker = new mapboxgl.Marker({ color: 'black', draggable: true })
      .setLngLat([lng, lat])
      .addTo(defaultMap)

    setMarker(marker)

    setInitMap(defaultMap)
  }, [])

  useEffect(() => {
    if (!(resultMapRef && resultMapRef.current)) return

    if (finalMap) {
      finalMap.remove()
    }

    const resultMap = new mapboxgl.Map({
      container: resultMapRef.current,
      style: `mapbox://styles/juan-moreno/${productColorToValue?.mapId}`,
      center: [lng2, lat2],
      zoom: zoom,
      dragPan: false,
      preserveDrawingBuffer: true,
      scrollZoom: false,
      boxZoom: false,
      doubleClickZoom: false,
      keyboard: false,
      maxZoom: INITIAL_COOR.MAX_ZOOM,
    })

    if (showDiamondMarker) {
      const el = document.createElement('div')
      el.className = `marker_diamond marker_diamond--${theResultColor}`

      const markerResult = new mapboxgl.Marker(el, { draggable: true })
        .setLngLat([lng, lat])
        .addTo(resultMap)

      markerResult.on('dragend', () => {
        handleMapChange(placeName)
      })
    }

    setFinalMap(resultMap)
  }, [mapResult, lng, lat])

  useEffect(() => {
    if (!initMap || !finalMap) return

    initMap.on('move', () => {
      const long = parseFloat(initMap.getCenter().lng.toFixed(4))
      const latitude = parseFloat(initMap.getCenter().lat.toFixed(4))
      const zoom = initMap.getZoom()
      const newZoom = parseFloat(zoom.toFixed(2))

      setLng(long)
      setLat(latitude)
      setLng2(long)
      setLat2(latitude)

      marker.setLngLat([long, latitude])

      setZoom(newZoom)
    })

    marker.on('dragend', (event: any) => {
      if (!event) return

      const { lng, lat } = event.target._lngLat

      initMap.setCenter([lng, lat])
      finalMap.setCenter([lng, lat])
    })

    // Clean up on unmount
    return () => {
      if (
        !(defaultMapRef && defaultMapRef.current) ||
        !(resultMapRef && resultMapRef.current)
      )
        return
      // initMap.remove()
      // finalMap.remove()
    }
  }, [initMap, finalMap, marker, defaultMapRef, resultMapRef])

  const handleMarkerChange = (optionId: string, valueId?: string) => {
    const selectedMarkerIndex = markerOption.values.findIndex(
      (val: any) => val.title === valueId,
    )
    const selectedMarker = markerOption.values[selectedMarkerIndex]

    switch (selectedMarkerIndex) {
      case 1:
        setMarkerImgSize('small')
        break
      case 2:
        setMarkerImgSize('mid')
        break
      case 3:
        setMarkerImgSize('large')
        break
      default:
        setMarkerImgSize('')
        break
    }

    if (selectedMarker) {
      setMarkerImg(selectedMarker.image)
    }

    onChange(optionId, valueId)
  }

  return (
    <>
      <ViewSwitcher>
        <ViewItem isActive={!mapResult} onClick={() => setMapResult(false)}>
          Map
        </ViewItem>
        <ViewItem isActive={mapResult} onClick={() => setMapResult(true)}>
          Result
        </ViewItem>
      </ViewSwitcher>
      <MapWrapper id="map-result">
        <MapPreview
          yellow={mapResult && theResultColor === 'yellow'}
          silver={mapResult && theResultColor === 'silver'}
          pink={mapResult && theResultColor === 'pink'}
        />
        {mapResult && markerImg && (
          <ImageMarker
            small={markerImgSize === 'small'}
            mid={markerImgSize === 'mid'}
            large={markerImgSize === 'large'}
            yellow={mapResult && theResultColor === 'yellow'}
            silver={mapResult && theResultColor === 'silver'}
            pink={mapResult && theResultColor === 'pink'}
          >
            <Media sizes={'50px'} layout={'fill'} {...markerImg} />
          </ImageMarker>
        )}

        <Map
          className="map-container"
          ref={resultMapRef}
          style={{
            opacity: `${mapResult ? 1 : 0}`,
            zIndex: `${mapResult ? 10 : -1}`,
            position: 'relative',
          }}
        />

        <Map
          className="map-container defaultMap"
          ref={defaultMapRef}
          style={{
            width: '100%',
            top: 0,
            opacity: `${mapResult ? 0 : 1}`,
            position: 'absolute',
          }}
        />
      </MapWrapper>

      {markerOption && (
        <ProductOption
          secondTitle={markerOption.title}
          option={markerOption}
          key={markerOption.title}
          onChange={handleMarkerChange}
        />
      )}

      <div className="values" style={{ display: 'none' }}>
        <span>lng: {lng} </span>
        <span>lat: {lat} </span>
        <span>zoom: {zoom} </span>
      </div>
    </>
  )
}
