import {
  BlockContent,
  Button,
  Media,
  Modal,
  ProductVariantPrice,
  ArrowInCircleButton,
} from '@magal/components'
import { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@magal/styles'
import { SectionProductConversionAreaProps, ShopifyData } from '@magal/models'
import { Close } from '@magal/icons'
import { useInView } from 'react-intersection-observer'

const HEADINGS = {
  heading: 'THE LOVE OF GIFTING',
}

const PackagingModalOpener = styled(Button, {
  display: 'grid',
  padding: '$12 0',
  borderBottom: '1px solid $green_09',
  color: '$green_09',
  alignItems: 'center',
  gridGap: '$12',
  gridTemplateColumns: '1fr auto',
  '@md': {
    gridTemplateColumns: 'auto 1fr auto',
    padding: '0',
  },
})
const Label1 = styled('div', { projectFont: 'body02', justifySelf: 'start' })
const Label2 = styled('div', {
  projectFont: 'body04',
  textDecoration: 'underline',
})
const IconWrap = styled('div', {
  display: 'none',
  '@md': { display: 'block', width: '$56' },
})
const ModalInner = styled('div', {
  maxWidth: '$maxWidthS',
  color: '$green_09',
  projectFont: 'body02',
  padding: '$containerMarginMobile',
  display: 'grid',
  gridGap: '$16',
  minHeight: '100%',
  alignContent: 'start',
  '@md': {
    padding: '$32',
    gridGap: '$32',
    borderLeft: '1px solid $green_09',
  },
})

const SelectButton = styled('button', {
  background: 'none',
  border: '1px solid $green_09',
  padding: '$8 14px',
  color: '$green_09',
  projectFont: 'heading05',
  '&:disabled': {
    opacity: 0.3,
  },
})

const CloseModalButton = styled(Button, {
  position: 'fixed',
  top: '0',
  right: '0',
  width: '$headerHeightMobile',
  height: '$headerHeightMobile',
  color: '$green_09',
  zIndex: 1,
})

const Header = styled('header', {
  marginBottom: '$12',
})

const Heading = styled('h2', {
  projectFont: 'heading05',
  placeSelf: 'start',
  pointerEvents: 'none',
})

const SubHeading = styled('h3', {
  projectFont: 'caps02',
  placeSelf: 'start',
  pointerEvents: 'none',
})
const Description = styled('div', {
  '@lg': {
    maxWidth: '80%',
  },
})

const CardsWrap = styled('div', {
  position: 'relative',
  display: 'grid',
  gridGap: '$16',
  gridAutoFlow: 'column',
  overflowX: 'auto',
  overflowY: 'hidden',
  gridAutoColumns: '340px',
  margin: '0 -$containerMarginMobile',
  padding: '0 $containerMarginMobile',
  scrollSnapType: 'x mandatory',
  scrollPadding: '0 $containerMarginMobile',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  '@lg': {
    scrollPadding: '0 $containerMarginDesktop',
    gridAutoColumns: '440px',
    gridGap: '$32',
    margin: '0 -$containerMarginDesktop',
    padding: '0 $containerMarginDesktop',
  },
})
const CardRoot = styled('div', {
  position: 'relative',
  color: '$green_09',
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'start',
  alignItems: 'start',
  gap: '$16',
  scrollSnapAlign: 'start',
})
const CardImage = styled('div', {
  width: '100%',
  background: '$gray300',
})
const CardTitle = styled('div', {
  projectFont: 'heading03',
})
const CardPrice = styled('div', {
  projectFont: 'caps04',
})
const ContentInfo = styled('div', {
  flexGrow: '1',
})
const CardDescription = styled('div', {
  projectFont: 'heading03',
})
const Controls = styled('div', {
  display: 'grid',
  justifyContent: 'start',
  gridTemplateColumns: 'auto auto',
  gridGap: '$8',
})
const SliderAnchor = styled('div', {
  position: 'absolute',
  top: '50%',
  variants: { last: { true: { right: 0 } } },
})
export const PackagingModal: FC<{
  packaging: SectionProductConversionAreaProps['packaging']
  onChange: (title: string, variantId?: string) => void
  shopifyData: ShopifyData
  selectedValue?: string
}> = ({ packaging, onChange, selectedValue, shopifyData }) => {
  const [modalPackagingOpen, setPackagingModalOpen] = useState(false)
  const [isPrevDisabled, setPrevDisabled] = useState(true)
  const [isNextDisabled, setNextDisabled] = useState(false)
  const [sliderStartAnchor, isSliderStartInView] = useInView({})
  const [sliderEndAnchor, isSliderEndInView] = useInView({})
  const sliderRef = useRef<HTMLDivElement | null>(null)

  const openModal = () => setPackagingModalOpen(true)
  const closeModal = () => setPackagingModalOpen(false)

  const scrollSlider = (numberOfSlides: number) => {
    if (sliderRef.current) {
      const distance = -numberOfSlides * 200
      sliderRef.current.scrollBy({
        left: distance,
        behavior: 'smooth',
      })
    }
  }

  const scrollNext = () => {
    scrollSlider(1)
  }
  const scrollPrev = () => {
    scrollSlider(-1)
  }

  useEffect(() => {
    if (isSliderEndInView) {
      setNextDisabled(true)
    } else {
      setNextDisabled(false)
    }
  }, [isSliderEndInView])

  useEffect(() => {
    if (isSliderStartInView) {
      setPrevDisabled(true)
    } else {
      setPrevDisabled(false)
    }
  }, [isSliderStartInView])

  if (!packaging || !packaging.enabled) return null

  return (
    <>
      <PackagingModalOpener onClick={openModal}>
        {packaging.icon && (
          <IconWrap>
            <Media {...packaging.icon} sizes={'50px'} />
          </IconWrap>
        )}
        <Label1>
          {selectedValue
            ? `Packaging: ${selectedValue}`
            : `Packaging (Standard, Premium, Gift)`}
        </Label1>
        <Label2>Choose</Label2>
      </PackagingModalOpener>
      <Modal
        isOpen={modalPackagingOpen}
        onRequestClose={closeModal}
        transition={'slide-from-right'}
        size={'stretch-y'}
        position={'right'}
      >
        <ModalInner>
          <CloseModalButton onClick={closeModal}>
            <Close />
          </CloseModalButton>
          <Header>
            <Heading>{HEADINGS.heading}</Heading>
            <SubHeading>{packaging.title}</SubHeading>
          </Header>
          <Description>
            <BlockContent body={packaging.description} />
          </Description>
          <CardsWrap ref={sliderRef}>
            {packaging.values.map((value, i) => {
              const visuallySelected =
                (!selectedValue && value._type === 'value-empty-variant') ||
                value.title === selectedValue
              return (
                <CardRoot key={value._key}>
                  {i === 0 && <SliderAnchor ref={sliderStartAnchor} />}
                  <ContentInfo>
                    <CardTitle>
                      {`${i === 0 ? 'Premium ' : ''}`}
                      {value.title}
                    </CardTitle>
                    {value.description && (
                      <CardDescription>
                        <BlockContent body={value.description} />
                      </CardDescription>
                    )}
                  </ContentInfo>
                  {value._type === 'value-variant' &&
                    value.variantId &&
                    shopifyData.variantBasic && (
                      <CardPrice>
                        <ProductVariantPrice
                          price={
                            shopifyData.variantBasic[value.variantId].priceV2
                          }
                        />
                      </CardPrice>
                    )}
                  <SelectButton
                    onClick={() => {
                      onChange(
                        value.title,
                        value._type === 'value-variant'
                          ? value.variantId
                          : undefined,
                      )
                      closeModal()
                    }}
                    disabled={visuallySelected}
                  >
                    {visuallySelected ? 'Selected' : 'Select'}
                  </SelectButton>
                  {visuallySelected && i !== 0 && (
                    <CardTitle>Free with every order</CardTitle>
                  )}
                  {i === packaging.values.length - 1 && (
                    <SliderAnchor ref={sliderEndAnchor} style={{ right: 0 }} />
                  )}
                  <CardImage>
                    {value.image?.mediaPayload && (
                      <Media
                        {...value.image}
                        sizes={'400px'}
                        hardcropRatio={'square'}
                      />
                    )}
                  </CardImage>
                </CardRoot>
              )
            })}
          </CardsWrap>
          <Controls>
            <ArrowInCircleButton
              onClick={scrollNext}
              disabled={isPrevDisabled}
            />
            <ArrowInCircleButton
              onClick={scrollPrev}
              disabled={isNextDisabled}
              type={'next'}
            />
          </Controls>
        </ModalInner>
      </Modal>
    </>
  )
}
