import Script from 'next/script'
import { FC, useEffect } from 'react'
import { ShopifyPrice } from '@magal/models'
import { styled } from '@magal/styles'

export type KlarnaWidgetProps = {
  price?: ShopifyPrice
}

const Root = styled('div', {
  height: '$48',
  display: 'grid',
  alignContent: 'center',
})

declare global {
  interface Window {
    KlarnaOnsiteService: Record<string, string>[]
  }
}

export const KlarnaWidget: FC<KlarnaWidgetProps> = ({ price }) => {
  const klarnaPrice = price ? parseFloat(price.amount) * 100 : undefined

  useEffect(() => {
    if (klarnaPrice) {
      window.KlarnaOnsiteService?.push({ eventName: 'refresh-placements' })
    }
  }, [klarnaPrice])

  if (!price) {
    return null
  }

  return klarnaPrice ? (
    <Root>
      <Script
        strategy={'lazyOnload'}
        src="https://na-library.klarnaservices.com/lib.js"
        data-client-id="022ca710-0d2f-5212-9f8c-69b796c93630"
      />
      {/*@ts-ignore*/}
      <klarna-placement
        data-key="credit-promotion-auto-size"
        data-locale="en-US"
        data-purchase-amount={klarnaPrice}
      />
    </Root>
  ) : null
}
