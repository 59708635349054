import { FC, useState, useEffect } from 'react'
import { client } from 'filestack-react'
import { PickerFileMetadata, PickerResponse, PickerOptions } from 'filestack-js'

import { styled } from '@magal/styles'
import { Button, Modal, Media } from '@magal/components'
import { OptionUploadFile } from '@magal/models'

const DEFAULT_TEXT = {
  CHANGE_FILE: 'Change your image',
  LOAD_FILE: 'Load your image',
}

const Root = styled('article', {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
})

const Image = styled('img', {
  width: '30px',
  height: '30px',
  objectFit: 'cover',
  border: '1px solid',
})

const ContainerImg = styled('div', {
  display: 'flex',
  gap: '10px',
  paddingLeft: '10px',
})

const ImgWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '15px',
})

const ModalTrigger = styled('div', {
  display: 'flex',
  width: '100%',
})

const ModalLabel = styled('p', {
  textDecoration: 'underline',
  cursor: 'pointer',
  projectFont: 'body01',
  fontSize: '15px',
  fontWeight: '500',
})

const ModalContent = styled('div', {
  display: 'grid',
  placeItems: 'center',
  borderSize: 'content-box',
  margin: 10,
  padding: 10,
  border: '1px solid #000',
  '@lg': {
    minWidth: '450px',
  },
})

const ImageWrapper = styled('div', {
  width: '100%',
})

export const ProductUploadFile: FC<{
  option: OptionUploadFile
  onChange: (optionId: string, valueId: string) => void
  numberOfFiles: number
}> = ({ option, onChange, numberOfFiles = 1 }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [files, setFiles] = useState<string[]>([])
  const [picker, setPicker] = useState<client.PickerInstance | null>(null)
  const fileStackKey = 'AitklXBUSjeGqbpvWwyLYz'
  const filePickerOptions: PickerOptions = {
    maxFiles: numberOfFiles,
    minFiles: numberOfFiles,
    onUploadDone: (files: PickerResponse) =>
      handleResponse(files.filesUploaded),
  }

  const filesStack = client.init(fileStackKey)

  useEffect(() => {
    const currentNumberOfFiles = option.selectedValue?.split(',').length || 0
    if (currentNumberOfFiles !== numberOfFiles) {
      onChange('_uploadedFile', '')
      setFiles([])
    } else {
      const newFiles = option?.selectedValue
        ? option?.selectedValue?.split(',')
        : []
      setFiles(newFiles)
    }
  }, [numberOfFiles, option.selectedValue])

  const handleResponse = (filesUploaded: PickerFileMetadata[]) => {
    const newFiles = filesUploaded.map((file) => file.url)
    setFiles(newFiles)
    onChange('_uploadedFile', newFiles.join(','))
  }

  const initPicker = () => {
    if (picker === null) {
      const newPicker = filesStack.picker(filePickerOptions)
      setPicker(newPicker)

      return newPicker
    }

    return picker
  }

  const handleClick = () => {
    const Picker = initPicker()

    if (Picker) {
      setFiles([])
      onChange('_uploadedFile', '')

      Picker.open()
    }
  }

  const checkAddPlural = numberOfFiles > 1 ? 's' : ''

  return (
    <Root>
      <Button appearance="outlineCurrentColor" onClick={handleClick}>
        {files.length > 0
          ? `${DEFAULT_TEXT.CHANGE_FILE}${checkAddPlural}`
          : `${DEFAULT_TEXT.LOAD_FILE}${checkAddPlural}`}
      </Button>

      <ImgWrapper>
        {files.length > 0 && (
          <ContainerImg>
            {files.map((image) => (
              <Image src={image} />
            ))}
          </ContainerImg>
        )}
      </ImgWrapper>

      {option.enableModal && (
        <>
          <ModalTrigger>
            <ModalLabel onClick={() => setIsOpen(!isOpen)}>
              {option.modalText}
            </ModalLabel>
          </ModalTrigger>

          <div>
            <Modal
              isOpen={isOpen}
              onRequestClose={() => setIsOpen(!isOpen)}
              variant="white-rounded"
            >
              <ModalContent>
                <ImageWrapper>
                  <Media {...option.modalContent} sizes={'500px'} />
                  <Media {...option.image} sizes={'500px'} />
                </ImageWrapper>
              </ModalContent>
            </Modal>
          </div>
        </>
      )}
    </Root>
  )
}
