import React, { FC } from 'react'
import { AdvancedProductOption } from '@magal/models'
import { ProductOption } from './ProductOption'
import {
  OnProductOptionChange,
  ProductOptionLabel,
} from '../SectionProductConversionArea'

export const ProductOptions: FC<{
  options?: AdvancedProductOption[]
  onChange: OnProductOptionChange
}> = ({ options, onChange }) => {
  return (
    <>
      {options?.map((option) => (
        <React.Fragment key={option._key}>
          <ProductOptionLabel>{option.title}</ProductOptionLabel>
          <ProductOption
            key={option._key}
            onChange={onChange}
            option={option}
          />
        </React.Fragment>
      ))}
    </>
  )
}
