import { BlockContent } from '@magal/components'
import { PortableText } from '@magal/models'
import { styled } from '@magal/styles'
import { FC } from 'react'
import { ProductAccordion } from '../ProductAccordion/ProductAccordion'

const Description = styled('div', {
  projectFont: 'body01',
  'p + p': {
    marginTop: '$24',
  },
  strong: {
    fontWeight: '500',
  },
})

export type AccordionWithContentProps = {
  title?: string
  description?: PortableText
}

export const AccordionWithContent: FC<AccordionWithContentProps> = ({
  title,
  description,
}) => {
  if (!description) return null

  return (
    <ProductAccordion title={title}>
      <Description>
        <BlockContent body={description} />
      </Description>
    </ProductAccordion>
  )
}
