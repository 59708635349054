import { FC, useEffect, useRef } from 'react'

interface Props {
  children: JSX.Element | JSX.Element[]
  intersect: (isIntersecting: boolean) => void
}

export const ContainerElements: FC<Props> = ({ children, intersect }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        intersect(entry?.isIntersecting ?? false)
      },
      { rootMargin: '-123px 0px -10px' },
    )

    observer.observe(ref.current as HTMLDivElement)
    return () => observer.disconnect()
  }, [intersect])

  return <div ref={ref}>{children}</div>
}
