import React, { FC } from 'react'
import { SectionJournalHeroProps } from '@magal/models'
import { Container, Link, Media, SectionWrap } from '@magal/components'
import { BlockContent } from '@magal/components'
import { styled } from '@magal/styles'

const Root = styled(Container, {
  color: '$green_09',
  display: 'grid',
  gap: '$32',
  '@lg': {
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
  },
})

const ImageContainer = styled('div', {
  height: '100%',
  width: '100%',
})

const StyledMedia = styled('div', {
  display: 'block',
  borderRadius: '$r2',
  overflow: 'hidden',
})

const CaptionTextContainer = styled('div', {
  marginTop: '$16',
})

const CaptionText = styled(Link, {
  projectFont: 'body01',
  marginLeft: '$containerMarginMobile',
  textDecoration: 'underline',
  '@md': {
    marginLeft: 0,
  },
})

const TitleAndImageContainer = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  order: 2,
  '@lg': {
    order: 1,
  },
})

const TextContainer = styled('div', {
  maxWidth: '$maxWidthS',
  order: 1,
  '@lg': {
    order: 2,
  },
})

const Title = styled('h1', {
  projectFont: 'heading01',
  textTransform: 'uppercase',
  marginBottom: '$24',
})

export const SectionJournalHero: FC<SectionJournalHeroProps> = ({
  title,
  description,
  mediaWithCaption,
  priority,
}) => {
  const isMediaVisible =
    mediaWithCaption?.image?.mediaPayload.type !== 'undefined-payload'
  return (
    <SectionWrap type={'MARGINS_S'}>
      <Root>
        <TitleAndImageContainer>
          {isMediaVisible && (
            <ImageContainer>
              <StyledMedia>
                {mediaWithCaption?.image && (
                  <Media
                    layout={'responsive'}
                    objectFit={'cover'}
                    priority={priority}
                    {...mediaWithCaption.image}
                  />
                )}
              </StyledMedia>
              {mediaWithCaption?.caption && (
                <CaptionTextContainer>
                  <CaptionText href={mediaWithCaption.link}>
                    {mediaWithCaption.caption}
                  </CaptionText>
                </CaptionTextContainer>
              )}
            </ImageContainer>
          )}
        </TitleAndImageContainer>
        <TextContainer>
          {title && <Title>{title}</Title>}
          {description && <BlockContent body={description} />}
        </TextContainer>
      </Root>
    </SectionWrap>
  )
}
