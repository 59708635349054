import { Indicators } from '@magal/components'
import { ShopifyMediaPayload } from '@magal/models'
import { styled } from '@magal/styles'
import { FC, useRef, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { ProductGalleryItem } from './ProductGalleryItem'

const Root = styled('div', {
  display: 'grid',
})
const ProductGalleryWrapper = styled('div', {
  gridArea: '1/1',
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 'min-content',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  width: '100vw',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  '@lg': {
    width: '100%',
    gap: '$6',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoFlow: 'inherit',
    gridAutoColumns: 'inherit',
    overflowX: 'inherit',
  },
})

const IndicatorsWrap = styled('div', {
  pointerEvents: 'none',
  zIndex: '1',
  gridArea: '1/1',
  margin: '$20',
  alignSelf: 'end',
  '@lg': {
    display: 'none',
  },
})

export type ProductGalleryProps = {
  images: ShopifyMediaPayload[] | []
  handle: string
}

export const ProductGallery: FC<ProductGalleryProps> = ({ images, handle }) => {
  const galleryRef = useRef<HTMLDivElement | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)

  // const getCurrentSlideNumber = () => {
  //   if (!galleryRef.current) {
  //     return 0
  //   }
  //   return Math.round(
  //     galleryRef.current.scrollLeft /
  //       galleryRef.current.children[0].clientWidth,
  //   )
  // }

  const scrollBySlides = (numberOfSlides: number) => {
    if (galleryRef.current) {
      const currentSlideWidth = galleryRef.current
        ? galleryRef.current.children[0].clientWidth
        : 0
      galleryRef.current?.scrollBy({
        left: numberOfSlides * currentSlideWidth,
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  // const scrollTo = (slideNumber: number) => {
  //   if (galleryRef.current) {
  //     const currentIndex = getCurrentSlideNumber()
  //     scrollBySlides(slideNumber - currentIndex)
  //   }
  // }

  if (!(images && images.length > 0)) return null

  return (
    <InView triggerOnce={true} threshold={0.5}>
      <Root>
        <ProductGalleryWrapper ref={galleryRef}>
          {images.map((image, index) => (
            <ProductGalleryItem
              image={image}
              imageSizes={'(min-width: 1000px) 33.334vw, 100vw'}
              slider={galleryRef.current}
              key={`${handle}-${index}`}
              onIntersecting={() => {
                setActiveIndex(index)
              }}
              priority={index <= 1}
            />
          ))}
        </ProductGalleryWrapper>

        {images.length > 1 && (
          <IndicatorsWrap>
            <Indicators
              numberOfItems={images.length}
              activeIndex={activeIndex}
              onItemClick={(clickedIndex) =>
                scrollBySlides(clickedIndex - activeIndex)
              }
            />
          </IndicatorsWrap>
        )}
      </Root>
    </InView>
  )
}
