import { ShopifyPriceRange } from '@magal/models'
import { styled } from '@magal/styles'
import { FC } from 'react'

const PriceLabel = styled('span', {
  color: '$darkGreen',
  projectFont: 'heading06',
  variants: {
    sale: {
      true: {
        color: '$red400',
        marginLeft: '$8',
        textDecoration: 'line-through',
      },
    },
  },
})

interface Props {
  compareAtPriceRange: ShopifyPriceRange
  priceRange: ShopifyPriceRange
}

export const Price: FC<Props> = ({ compareAtPriceRange, priceRange }) => {
  const { minVariantPrice } = priceRange
  const { minVariantPrice: compareMinPrice } = compareAtPriceRange

  const currency = minVariantPrice?.currencyCode
  const optionMax = { style: 'currency', currency }
  const newMax = new Intl.NumberFormat('en-US', optionMax)
  const maxAmount = +compareMinPrice?.amount ?? 0
  const minAmount = +minVariantPrice?.amount ?? 0
  const maxPrice = newMax.format(maxAmount)
  const minPrice = newMax.format(minAmount)

  return (
    <span>
      {!(maxAmount === minAmount) && <PriceLabel>{minPrice}</PriceLabel>}
      {maxAmount > 0 && (
        <PriceLabel sale={maxAmount > minAmount}>{maxPrice}</PriceLabel>
      )}
    </span>
  )
}
