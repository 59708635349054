import { FC, useRef } from 'react'
import {
  AccordionStateful,
  BlockContent,
  Container,
  SectionWrap,
} from '@magal/components'
import { styled } from '@magal/styles'
import { SectionUtilityPageProps } from '@magal/models'
import { InView } from 'react-intersection-observer'
import { MenuItem } from './MenuItem'
import AccordionHeading from './AccordionHeading'

const RootWrap = styled('div', {
  color: '$green_09',
  display: 'grid',
  gap: '$40',
  '@lg': {
    gap: '$12',
    gridTemplateColumns: '1fr 3fr',
  },
  gridAutoColumns: '100%',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
})

const MenuContainerWrap = styled('div', {
  '@md': {
    marginLeft: '$containerMarginDesktop',
    marginRight: '$containerMarginDesktop',
  },
})

const Root = styled(Container, {})

const titleGaps = 16

const MenuContainer = styled('div', {
  display: 'grid',
  gap: '$16',
  gridAutoFlow: 'column',
  gridAutoColumns: 'minmax(min-content, max-content)',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  padding: '$16 $containerMarginMobile',
  borderTop: '1px solid $green_09',
  borderBottom: '1px solid $green_09',
  '@md': {
    padding: '$16 $containerMarginDesktop',
  },
  '@lg': {
    border: 'none',
    gridAutoFlow: 'row',
    gridAutoRows: 'minmax(min-content, max-content)',
  },
})

const AccordionsWrap = styled('div', {
  display: 'grid',
  gridAutoRows: 'minmax(min-content, max-content)',
  gap: '$12',
})

const AccordionWrap = styled('div', {
  display: 'grid',
  backgroundColor: '$gray300',
  borderRadius: '$r2',
})

const DescriptionText = styled('div', {
  backgroundColor: '$gray300',
  borderRadius: '$r2',
  maxWidth: '$maxWidthS',
  padding: '$16',
  projectFont: 'body01',
})

export const SectionUtilityPage: FC<SectionUtilityPageProps> = ({
  title,
  utilityPages,
  panels,
  description,
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null)

  const getCurrentSlideNumber = () => {
    if (!sliderRef.current) {
      return 0
    }
    return Math.round(
      sliderRef.current.scrollLeft / sliderRef.current.children[0].clientWidth,
    )
  }

  const getCurrentIndex = () => {
    if (utilityPages) {
      return utilityPages.findIndex((utilityPage) => {
        return utilityPage.title === title
      })
    }
    return 0
  }

  const scrollBySlides = (numberOfSlides: number) => {
    if (sliderRef.current) {
      let numberOfSlidesWidth = -titleGaps
      for (let i = 0; i < numberOfSlides; i++) {
        numberOfSlidesWidth += sliderRef.current
          ? sliderRef.current.children[i].clientWidth + titleGaps
          : 0
      }
      sliderRef.current?.scrollBy({
        left: numberOfSlidesWidth,
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const scrollTo = (slideNumber: number) => {
    if (sliderRef.current) {
      const currentIndex = getCurrentSlideNumber()
      scrollBySlides(slideNumber - currentIndex)
    }
  }

  return (
    <SectionWrap type={'MARGINS_M'}>
      <RootWrap>
        <MenuContainerWrap>
          <InView
            triggerOnce={true}
            threshold={0.5}
            onChange={(inView) => {
              if (inView) {
                scrollBySlides(getCurrentIndex())
              }
            }}
          >
            <MenuContainer ref={sliderRef}>
              {utilityPages?.map((utilityPage, index) => {
                return (
                  <MenuItem
                    slider={sliderRef.current}
                    panel={utilityPage}
                    key={index}
                    href={utilityPage.href}
                    isActive={utilityPage.title === title}
                    onClick={() => scrollTo(index)}
                  />
                )
              })}
            </MenuContainer>
          </InView>
        </MenuContainerWrap>
        <Root>
          <AccordionsWrap>
            {description && (
              <AccordionWrap>
                <DescriptionText>
                  <BlockContent body={description} />
                </DescriptionText>
              </AccordionWrap>
            )}
            {panels &&
              panels?.map((panel, index) => (
                <AccordionWrap key={index}>
                  <AccordionStateful
                    accordionId={`accordion-${index}`}
                    key={`accordion-${title}-${index}`}
                    header={(headerElementProps) => (
                      <AccordionHeading
                        {...headerElementProps}
                        title={panel.title}
                      />
                    )}
                  >
                    <DescriptionText>
                      <BlockContent body={panel.description} />
                    </DescriptionText>
                  </AccordionStateful>
                </AccordionWrap>
              ))}
          </AccordionsWrap>
        </Root>
      </RootWrap>
    </SectionWrap>
  )
}
