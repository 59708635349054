import {
  ShopifyData,
  AdvancedTemplate,
  AdvancedProductOption,
  ShopifyProductExtended,
  ShopifyProductVariant,
  ShopifyPrice,
} from '@magal/models'
import { ProductDetailPageType } from './SectionProductConversionArea'
import { useState } from 'react'
import {
  EMPTY_VALUE_STRING_ALIAS,
  PACKAGING_ATTRIBUTE_KEY,
} from '@magal/configs'
import { captureException } from '@sentry/nextjs'

const getVariantsTotalPrice = (
  variants: ShopifyProductVariant[],
): ShopifyPrice => ({
  amount: variants.reduce((acc, val) => {
    const sum =
      Math.round((parseFloat(acc) + parseFloat(val.priceV2.amount)) * 100) / 100
    return sum.toString()
  }, '0'),
  currencyCode: variants[0].priceV2.currencyCode,
})

type SelectedOptionType = Record<string, string | undefined>

const getVariantBySelectedOptions = (
  variants: ShopifyProductVariant[],
  selectedOptions: SelectedOptionType,
) => {
  return (
    variants.find((variant) =>
      variant.selectedOptions.every(
        (option) => selectedOptions[option.name] === option.value,
      ),
    ) ?? undefined
  )
}

export const useComputedProductData = (
  productData: ShopifyProductExtended,
  shopifyData: ShopifyData,
  advancedTemplate?: AdvancedTemplate,
) => {
  const { options: productOptions, variants } = productData

  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionType>({})
  const [selectedPackaging, setSelectedPackaging] = useState<
    { variantId?: string; title: string } | undefined
  >(undefined)

  const _isOptionValueSelected = (optionName: string, optionValue: string) => {
    return selectedOptions[optionName] === optionValue
  }

  const _getMultiplier = (multiplylingOptionName: string) => {
    const val = selectedOptions
      ? selectedOptions[multiplylingOptionName]
      : undefined
    return val ? parseInt(val) : undefined
  }

  const pageType: ProductDetailPageType = (() => {
    if (advancedTemplate) return 'ADVANCED_TEMPLATE'
    if (variants.length === 1) return 'SINGLE_VARIANT'
    if (productOptions && productOptions.length >= 1)
      return 'VARIANT_DERIVED_FROM_OPTIONS'

    captureException(new Error(`Wrong ProductPageType`))
    return undefined
  })()

  const onProductOptionChange = (optionId: string, valueId?: string) => {
    setSelectedOptions({
      ...selectedOptions,
      [optionId]: valueId,
    })
  }

  const onPackagingChange = (title: string, variantId?: string) => {
    setSelectedPackaging({ variantId, title })
  }

  const computedProductOptions: AdvancedProductOption[] = (() => {
    if (pageType === 'VARIANT_DERIVED_FROM_OPTIONS') {
      return (
        productOptions?.map((option) => ({
          _type: 'basic',
          _key: option.name,
          title: option.name,
          selectedValue: selectedOptions[option.name],
          values: option.values.map((val) => ({
            _type: 'value-basic',
            _key: val,
            title: val,
            optionId: option.name,
            // disabled: !getVariantBySelectedOptions(variants, {
            //   ...selectedOptions,
            //   [option.name]: val,
            // }),
          })),
        })) ?? []
      )
    }
    if (pageType === 'ADVANCED_TEMPLATE') {
      return (
        advancedTemplate?.options
          .reduce((acc: AdvancedProductOption[], option) => {
            let newOptions: AdvancedProductOption[] = []

            switch (option._type) {
              case 'shopify': {
                const shopifyProductOption = productOptions?.find(
                  (productOption) => productOption.name === option.title,
                )
                if (shopifyProductOption) {
                  newOptions = [
                    {
                      ...option,
                      values: shopifyProductOption.values.map((v) => {
                        return {
                          title: v,
                          optionId: option.title,
                        }
                      }),
                    },
                  ]
                }
                break
              }
              case 'shopify-image': {
                const shopifyProductOption = productOptions?.find(
                  (productOption) => productOption.name === option.title,
                )
                if (!shopifyProductOption) return acc
                newOptions = [
                  {
                    ...option,
                    values: shopifyProductOption.values.map((shopifyVal) => {
                      const foundOption = option.values.find(
                        (v) => v.title === shopifyVal,
                      )
                      return (
                        foundOption ?? {
                          _type: 'value-image',
                          _key: shopifyVal,
                          title: shopifyVal,
                          optionId: option.title,
                        }
                      )
                    }),
                  },
                ]
                break
              }
              case 'shopify-color': {
                const shopifyProductOption = productOptions?.find(
                  (productOption) => productOption.name === option.title,
                )
                if (!shopifyProductOption) return acc
                newOptions = [
                  {
                    ...option,
                    values: shopifyProductOption.values.map((shopifyVal) => {
                      const foundOption = option.values.find(
                        (v) => v.title === shopifyVal,
                      )
                      return (
                        foundOption ?? {
                          _type: 'value-color',
                          _key: shopifyVal,
                          title: shopifyVal,
                          optionId: option.title,
                        }
                      )
                    }),
                  },
                ]
                break
              }
              case 'additional-variant': {
                newOptions = [
                  {
                    ...option,
                    values: option.values?.map((v) => {
                      if (v._type === 'value-variant') {
                        const price = v.variantId
                          ? shopifyData.variantBasic
                            ? shopifyData.variantBasic[v.variantId]?.priceV2
                            : undefined
                          : undefined
                        return price ? { ...v, price } : v
                      } else return v
                    }),
                  },
                ]
                break
              }
              case 'multi-basic': {
                const multiplier = _getMultiplier(option.multiplierOptionTitle)
                if (!multiplier) break

                for (let i = 1; i <= multiplier; i++) {
                  const optionId = `${option.title} ${i}`
                  newOptions.push({
                    ...option,
                    title: optionId,
                    values: option.values.map((value) => {
                      return {
                        ...value,
                        optionId,
                        selected: _isOptionValueSelected(optionId, value.title),
                      }
                    }, []),
                  })
                }
                break
              }
              case 'multi-color': {
                const multiplier = _getMultiplier(option.multiplierOptionTitle)
                if (!multiplier) break

                for (let i = 1; i <= multiplier; i++) {
                  const optionId = `${option.title} ${i}`
                  newOptions.push({
                    ...option,
                    title: optionId,
                    values: option.values.map((value) => {
                      return {
                        ...value,
                        optionId,
                      }
                    }, []),
                  })
                }
                break
              }
              case 'multi-image': {
                const multiplier = _getMultiplier(option.multiplierOptionTitle)
                if (!multiplier) break

                for (let i = 1; i <= multiplier; i++) {
                  const optionId = `${option.title} ${i}`
                  newOptions.push({
                    ...option,
                    title: optionId,
                    values: option.values.map((value) => {
                      return {
                        ...value,
                        optionId,
                      }
                    }, []),
                  })
                }
                break
              }
              case 'multi-text-input': {
                const multiplier = _getMultiplier(option.multiplierOptionTitle)
                if (!multiplier) break

                for (let i = 1; i <= multiplier; i++) {
                  const optionId = `${option.title} ${i}`
                  newOptions.push({
                    ...option,
                    title: optionId,
                  })
                }

                break
              }
              case 'text-input': {
                if (
                  !option.appearsConditionally ||
                  (option.appearsConditionally &&
                    option.parentOption &&
                    selectedOptions[option.parentOption] &&
                    selectedOptions[option.parentOption] !==
                      EMPTY_VALUE_STRING_ALIAS)
                ) {
                  const maxLength = option.maxLengthBasedOnParentOption
                    ? option.parentOption
                      ? _getMultiplier(option.parentOption)
                      : undefined
                    : option.maxLength
                  newOptions = [{ ...option, maxLength, selectedValue: '' }]
                }
                break
              }
              case 'upload-file': {
                newOptions = [
                  {
                    ...option,
                    title: '_uploadedFile',
                  },
                ]
                break
              }
              case 'map': {
                newOptions = [
                  {
                    ...option,
                    title: 'Map Address',
                  },
                ]
                break
              }
              default: {
                newOptions = [option]
                break
              }
            }
            return [...acc, ...newOptions]
          }, [])
          .map((o) => ({
            ...o,
            selectedValue: selectedOptions[o.title],
          })) ?? []
      )
    }
    return []
  })()

  const currentMainVariant =
    (() => {
      if (pageType === 'SINGLE_VARIANT' || !pageType) {
        return variants[0]
      }
      if (
        pageType === 'VARIANT_DERIVED_FROM_OPTIONS' &&
        productOptions.length === Object.entries(selectedOptions).length
      ) {
        //find variant that has same selectedOptions as

        const variant = getVariantBySelectedOptions(variants, selectedOptions)

        if (!variant) {
          captureException(new Error('Variant with selected options not found'))

          return undefined
        }
        return variant
      }
      if (pageType === 'ADVANCED_TEMPLATE') {
        if (variants.length === 1) return variants[0]
        const variant = getVariantBySelectedOptions(variants, selectedOptions)
        return variant ?? undefined
      }
      return undefined
    })() || undefined

  const currentSoftMainVariant =
    (() => {
      if (pageType === 'SINGLE_VARIANT' || !pageType) {
        return variants[0]
      }
      if (
        pageType === 'VARIANT_DERIVED_FROM_OPTIONS' ||
        pageType === 'ADVANCED_TEMPLATE'
      ) {
        //find variant that has same selectedOptions as

        const variant = getVariantBySelectedOptions(
          variants,
          productOptions.reduce((acc, productOption) => {
            if (selectedOptions[productOption.name]) {
              return {
                ...acc,
                [productOption.name]: selectedOptions[productOption.name],
              }
            }

            return {
              ...acc,
              [productOption.name]: productOption.values[0],
            }
          }, {}),
        )

        if (!variant) {
          captureException(new Error('Variant with selected options not found'))
          return undefined
        }

        return variant
      }

      return undefined
    })() || undefined

  const _currentPackagingVariant = selectedPackaging?.variantId
    ? shopifyData.variantBasic
      ? shopifyData.variantBasic[selectedPackaging.variantId]
      : undefined
    : undefined

  const attributes = (() => {
    const _attributes: { key: string; value: string }[] = []

    if (_currentPackagingVariant && selectedPackaging)
      _attributes.push({
        key: PACKAGING_ATTRIBUTE_KEY,
        value: selectedPackaging.title,
      })

    if (pageType === 'ADVANCED_TEMPLATE') {
      return computedProductOptions.reduce((acc, val) => {
        if (!val.selectedValue) return acc
        const value = val.selectedValue === '' ? 'EMPTY' : val.selectedValue
        return [
          ...acc,
          {
            key: val.title,
            value,
          },
        ]
      }, _attributes)
    }
    return _attributes
  })()

  const currentAdditionalVariants = (() => {
    const _variants: ShopifyProductVariant[] = []

    // PACKAGING
    if (_currentPackagingVariant) _variants.push(_currentPackagingVariant)

    // ADDITIONAL VARIANTS
    if (pageType === 'ADVANCED_TEMPLATE') {
      return computedProductOptions.reduce((acc, val) => {
        if (val._type === 'additional-variant' && val.selectedValue) {
          const selectedValue = val.values.find(
            (v) => v.title === val.selectedValue,
          )
          const selectedValueId =
            selectedValue?._type === 'value-variant'
              ? selectedValue.variantId
              : undefined
          if (selectedValueId) {
            const variant = shopifyData.variantBasic
              ? shopifyData.variantBasic[selectedValueId]
              : undefined
            if (variant) {
              return [...acc, variant]
            }

            return acc
          }
        }
        return acc
      }, _variants)
    }
    return _variants
  })()

  const currentPrice = (() => {
    if (currentAdditionalVariants && currentSoftMainVariant) {
      return getVariantsTotalPrice([
        currentSoftMainVariant,
        ...currentAdditionalVariants,
      ])
    }

    if (!currentMainVariant) return currentSoftMainVariant?.priceV2 ?? undefined

    return currentAdditionalVariants.length
      ? getVariantsTotalPrice([
          currentMainVariant,
          ...currentAdditionalVariants,
        ])
      : currentMainVariant?.priceV2
  })()

  const currentCompareAtPrice = (() => {
    if (currentSoftMainVariant?.id || currentAdditionalVariants.length === 0)
      return currentSoftMainVariant?.compareAtPriceV2
    return undefined
  })()

  const currentPackagingId = (() => {
    return selectedPackaging
  })()

  return {
    currentPackagingId,
    currentMainVariant,
    currentSoftMainVariant,
    currentAdditionalVariants,
    currentPrice,
    currentCompareAtPrice,
    attributes,
    computedProductOptions,
    onProductOptionChange,
    onPackagingChange,
    pageType,
    isConfiguratorDirty: Object.keys(selectedOptions).length > 0,
  } as const
}
