import { FC, useState } from 'react'
import { AdvancedProductOption } from '@magal/models'
import { styled } from '@magal/styles'
import { useTranslation } from 'react-i18next'
import { ChevronDown } from '@magal/icons'
import React from 'react'
import {
  Media,
  ProductVariantPrice,
  SwatchInner,
  SwatchWrap,
} from '@magal/components'
import { EMPTY_VALUE_STRING_ALIAS } from '@magal/configs'

const Root = styled('div', {
  display: 'grid',
  position: 'relative',
  gap: '$16',
})
const TextInputWrap = styled('div', {
  display: 'grid',
  position: 'relative',
  input: {
    borderRadius: 0,
    backgroundColor: 'transparent',
    height: '$40',
    border: 0,
    margin: 0,
    projectFont: 'body01',
    color: '$green_09',
    borderBottom: '1px solid currentColor',
    width: '100%',
  },
  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
  },
})
const Title = styled('div', {
  projectFont: 'body05',
  textAlign: 'center',
  userSelect: 'none',
})

const CheckboxWrap = styled('div', {
  input: { position: 'absolute', appearance: 'none' },
  label: {
    display: 'inline-grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: '$8',
    alignItems: 'center',
    projectFont: 'body04',
    lineHeight: 1,
    cursor: 'pointer',
    paddingRight: '$8',
  },
})
const Tick = styled('div', {
  width: '$16',
  height: '$16',
  border: '1px solid currentColor',
  position: 'relative',
  display: 'grid',
  placeItems: 'center',
  variants: {
    selected: {
      true: {
        '&::after': {
          content: '',
          display: 'block',
          position: 'absolute',
          width: '$8',
          height: '$8',
          background: 'currentColor',
        },
      },
    },
  },
})
const InputExtraLabel = styled('div', {
  position: 'absolute',
  placeSelf: 'center end',
  projectFont: 'body02',
  color: '$gray600',
})

const SelectWrap = styled('div', {
  position: 'relative',
  display: 'grid',
})
const Select = styled('select', {
  projectFont: 'body01',
  color: '$green_09',
  backgroundColor: '$gray400',
  border: 'none',
  width: '100%',
  borderRadius: '$r2',
  padding: '$12 $20',
  height: '50px',
  display: 'grid',
  alignItems: 'center',
  appearance: 'none',
  gridRow: 1,
  gridColumn: 1,
})
const IconWrap = styled('div', {
  display: 'grid',
  gridRow: 1,
  gridColumn: 1,
  justifyContent: 'end',
  alignItems: 'center',
  padding: '$12 $20',
  pointerEvents: 'none',
})
const RadioWrap = styled('div', {
  display: 'inline-grid',
  margin: '0 auto',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  padding: '0 $12',
  input: { position: 'absolute', appearance: 'none' },
  '@md': {
    justifyContent: 'center',
    flexWrap: 'wrap',
    display: 'flex',
  },
})
const VariantLabel = styled('label', {
  display: 'grid',
  placeItems: 'center',
  textAlign: 'center',
  color: '$green_09',
  projectFont: 'caps07',
  padding: '$12',
  borderRadius: '$r1',
  border: '1px solid $green_09',
  variants: {
    selected: {
      true: {
        color: 'white',
        background: '$green_09',
      },
    },
  },
})
const SliderWrap = styled('div', {
  overflowX: 'auto',
  margin: '0 -$containerMarginMobile',
  display: 'flex',
  flexDirection: 'column',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  '@lg': {
    overflowX: 'visible',
    margin: 0,
  },
})
const VariantRadioWrap = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: '$8',
  flexWrap: 'wrap',
  input: { position: 'absolute', appearance: 'none' },
  label: { cursor: 'pointer' },
})
const BottomMessage = styled('div', {
  projectFont: 'caps07',
  height: '1em',
  textAlign: 'center',
  variants: {
    isGrey: {
      true: {
        color: '$gray500',
      },
    },
  },
})

const EMPTY_INPUT_VALUE_ALIAS = 'none'
export const ProductOption: FC<{
  secondTitle?: string
  option: AdvancedProductOption
  onChange: (optionId: string, valueId?: string, attrs?: any) => void
  onGoToNextStep?: () => void
}> = ({ option, onChange, onGoToNextStep, secondTitle }) => {
  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const el = e.target as HTMLSelectElement
    onChange(option.title, el.value)
  }

  const [extraLabel, setExtraLabel] = useState<string | undefined>(undefined)
  const [explicitlyEmpty, setExplicitlyEmpty] = useState(false)

  const { t } = useTranslation('sectionProductConversionArea')

  const isSelect =
    option._type === 'shopify' ||
    option._type === 'basic' ||
    option._type === 'multi-basic'

  const isColorRadio =
    option._type === 'color' ||
    option._type === 'multi-color' ||
    option._type === 'shopify-color'

  const isImageRadio =
    option._type === 'image' ||
    option._type === 'multi-image' ||
    option._type === 'shopify-image'

  const isRadio = isImageRadio || isColorRadio

  const isVariantPicker = option._type === 'additional-variant'
  const isTextInput =
    option._type === 'text-input' || option._type === 'multi-text-input'

  const isDateInput = option._type === 'date-input'

  const computedExtraLabel = (() => {
    if (isSelect || isTextInput || isDateInput) return undefined
    return extraLabel ?? option.selectedValue ?? t('chooseOption')
  })()

  return (
    <Root>
      {isSelect && option?.values && (
        <SelectWrap>
          <Select onChange={handleChange} value={option.selectedValue}>
            <option value="select">{t('chooseOption')}</option>
            {Array.isArray(option.values) &&
              option.values.map((val) => (
                <option
                  value={val.title}
                  key={val.title + option.title}
                  disabled={val.disabled}
                >
                  {val.title}
                </option>
              ))}
          </Select>
          <IconWrap>
            <ChevronDown />
          </IconWrap>
        </SelectWrap>
      )}
      {isRadio && (
        <>
          {secondTitle && <Title>{secondTitle}</Title>}
          <SliderWrap>
            <RadioWrap>
              {Array.isArray(option.values) &&
                option.values.map((val) => (
                  <React.Fragment key={val.title + option.title}>
                    <input
                      type="radio"
                      id={option.title + val.title}
                      name={option.title}
                      value={val.title}
                      onChange={() => {
                        onChange(option.title, val.title)
                      }}
                    />
                    <SwatchWrap
                      as={'label'}
                      isActive={option.selectedValue === val.title}
                      htmlFor={option.title + val.title}
                      title={val.title}
                      onMouseEnter={() => setExtraLabel(val.title)}
                      onMouseLeave={() => setExtraLabel(undefined)}
                    >
                      <SwatchInner
                        style={
                          val._type === 'value-color'
                            ? {
                                background: val?.color?.hex ?? 'transparent',
                              }
                            : undefined
                        }
                      >
                        {val._type === 'value-image' && val.image && (
                          <Media
                            sizes={'50px'}
                            layout={'fill'}
                            {...val.image}
                          />
                        )}
                      </SwatchInner>
                    </SwatchWrap>
                  </React.Fragment>
                ))}
            </RadioWrap>
          </SliderWrap>
        </>
      )}
      {isVariantPicker && (
        <VariantRadioWrap>
          {Array.isArray(option.values) &&
            option.values.map((val) => (
              <React.Fragment key={val.title + option.title}>
                <input
                  type="radio"
                  id={option.title + val.title}
                  name={option.title}
                  value={val.title}
                  onChange={() => {
                    onChange(
                      option.title,
                      val._type === 'value-empty-variant'
                        ? EMPTY_VALUE_STRING_ALIAS
                        : val.title,
                    )
                    if (
                      val._type === 'value-variant' &&
                      option.onSelectGoNext &&
                      onGoToNextStep
                    ) {
                      onGoToNextStep()
                    }
                  }}
                />
                <VariantLabel
                  htmlFor={option.title + val.title}
                  title={val.title}
                  selected={
                    (val._type === 'value-empty-variant' &&
                      option.selectedValue === EMPTY_VALUE_STRING_ALIAS) ||
                    (val._type === 'value-variant' &&
                      option.selectedValue === val.title)
                  }
                >
                  {val.title}
                  {val._type === 'value-variant' && val.price && (
                    <div>
                      + <ProductVariantPrice price={val.price} />
                    </div>
                  )}
                </VariantLabel>
              </React.Fragment>
            ))}
        </VariantRadioWrap>
      )}
      {isTextInput && (
        <>
          <TextInputWrap disabled={explicitlyEmpty}>
            <input
              type={'text'}
              onChange={(e) => {
                const val = e.target.value
                if (
                  !option.maxLength ||
                  (option.maxLength && val.length <= option.maxLength)
                ) {
                  onChange(option.title, val)
                }
              }}
              value={option.selectedValue}
              disabled={explicitlyEmpty}
            />
            <InputExtraLabel>
              {!explicitlyEmpty &&
                `${option.selectedValue?.length ?? 0} / ${
                  option.maxLength
                } characters`}
            </InputExtraLabel>
          </TextInputWrap>

          <CheckboxWrap>
            <input
              type={'checkbox'}
              onChange={() => {
                if (!explicitlyEmpty) {
                  onChange(option.title, EMPTY_INPUT_VALUE_ALIAS)
                } else {
                  onChange(option.title, '')
                }
                setExplicitlyEmpty(!explicitlyEmpty)
              }}
              id={`checkbox-${option.title}`}
              checked={explicitlyEmpty}
            />
            <label htmlFor={`checkbox-${option.title}`}>
              <Tick selected={explicitlyEmpty} />
              <span>Leave empty</span>
            </label>
          </CheckboxWrap>
        </>
      )}
      {isDateInput && (
        <>
          <TextInputWrap disabled={explicitlyEmpty}>
            <input
              type={'date'}
              onChange={(e) => {
                const val = e.target.value
                if (val) {
                  onChange(option.title, val)
                }
              }}
              value={option.selectedValue}
              disabled={explicitlyEmpty}
            />
          </TextInputWrap>

          <CheckboxWrap>
            <input
              type={'checkbox'}
              min={'1900-01-01'}
              max={'2030-12-31'}
              pattern="\d{4}-\d{2}-\d{2}"
              onChange={() => {
                if (!explicitlyEmpty) {
                  onChange(option.title, EMPTY_INPUT_VALUE_ALIAS)
                } else {
                  onChange(option.title, undefined)
                }
                setExplicitlyEmpty(!explicitlyEmpty)
              }}
              id={`checkbox-${option.title}`}
              checked={explicitlyEmpty}
              required={!explicitlyEmpty}
            />
            <label htmlFor={`checkbox-${option.title}`}>
              <Tick selected={explicitlyEmpty} />
              <span>Leave empty</span>
            </label>
          </CheckboxWrap>
        </>
      )}
      {computedExtraLabel && (
        <BottomMessage isGrey={!option.selectedValue}>
          {computedExtraLabel}
        </BottomMessage>
      )}
    </Root>
  )
}
