import { Button, EditorialModal } from '@magal/components'
import { PortableText } from '@magal/models'
import { styled } from '@magal/styles'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

const SizeGuideWrap = styled('div', {
  projectFont: 'body01',
  textAlign: 'right',
})

const SizeGuideLink = styled(Button, {
  projectFont: 'body04',
  textDecoration: 'underline',
  color: '$green_09',
})

export type SizeGuideProps = {
  modalTitle: string
  modalContent: PortableText
}

export const SizeGuide: FC<SizeGuideProps> = ({ modalTitle, modalContent }) => {
  const { t } = useTranslation('sizeGuide')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const toggleModalState = () => setIsOpenModal((prevIsOpen) => !prevIsOpen)

  return (
    <SizeGuideWrap>
      <SizeGuideLink onClick={toggleModalState}>{t('sizeGuide')}</SizeGuideLink>
      <EditorialModal
        modalTitle={modalTitle}
        modalContent={modalContent}
        isOpen={isOpenModal}
        onRequestClose={toggleModalState}
      />
    </SizeGuideWrap>
  )
}
